import { useField, useFormikContext } from 'formik';
import React from 'react';
import { ActivityIndicator } from 'react-native';
import { Button } from 'react-native-elements';
import * as Yup from 'yup';
import { WebContainer } from '../../components';
import TextInput from '../../components/TextInput';
import { Form, StyledModelPicker } from '../form_utils';

const UrlField = (props) => {
  const {
    values: { title, url },
    setFieldValue,
  } = useFormikContext();
  const [field, meta] = useField(props);
  let loading = false;

  const onBlur = () => {
    if (url && !title) {
      const key = '074d3df56458e07efd09e578ce9adf9b';
      const lpUrl = `http://api.linkpreview.net/?key=${key}&q=${url}`;
      fetch(lpUrl)
        .then((response) => response.json())
        .then((data) => {
          if (!title) {
            setFieldValue('title', data.title);
            setFieldValue('description', data.description);
            setFieldValue('image', data.image);
          }
        })
        .catch((error) => {
          console.error('Error:', error);
        });
    }
  };

  return (
    <>
      <TextInput {...props} onBlur={onBlur} />
      {loading && <ActivityIndicator size='small' color='#aaa' />}
    </>
  );
};

export const createModelForm = (metadata) => {
  return (formikProps) => {
    const {
      handleChange,
      handleBlur,
      handleSubmit,
      values,
      errors,
      setFieldValue,
      touched,
      isValid,
    } = formikProps;

    return (
      <Form style={{ padding: 10 }}>
        <StyledModelPicker label='Asset Type' name='asset_type' metadata={metadata} />
        <StyledModelPicker label='Intent' name='intent' metadata={metadata} />
        <StyledModelPicker label='Persona' name='persona' metadata={metadata} />
        <StyledModelPicker label='Module' name='module' metadata={metadata} />

        <UrlField label='URL' name='url' onEndEditing={handleBlur} metadata={metadata}/>
        <TextInput label='Title' name='title' maxLength={200} autoCapitalize='words' metadata={metadata}/>
        <TextInput label='Description' name='description' multiline numberOfLines={4} metadata={metadata}/>
        <UrlField label='Image' name='image' onEndEditing={handleBlur} metadata={metadata}/>
        <TextInput label='Remarks' name='remarks' multiline numberOfLines={4} metadata={metadata}/>
        <WebContainer>
          <Button disabled={!isValid} onPress={handleSubmit} title='SUBMIT' />
        </WebContainer>
      </Form>
    );
  };
};

export const validationSchema = Yup.object().shape({
  asset_type: Yup.string().label('Asset Type').required(),
  intent: Yup.string().label('Intent').required(),
  persona: Yup.string().label('Persona').required(),
  title: Yup.string().label('Title').required(),
  url: Yup.string().url().label('URL').required(),
  image: Yup.string().url().label('Image'),
});
