import { connect, getIn } from 'formik';
import React from 'react';
import { Switch } from 'react-native';
import { Asterisk } from '../styles/components';
import { ErrorText, FieldWrapper, LabelText } from '../styles/controls';

export const StyledSwitch = (props) => {
  const { label, name, metadata, formik, ...rest } = props;
  const { values, errors, setFieldValue } = formik;

  const value = getIn(values, name);
  const error = getIn(errors, name);
  const touched = getIn(touched, name);
  const isRequired = metadata.isFieldRequired(name);

  return (
    <FieldWrapper>
      <LabelText>
        {label} {isRequired ? <Asterisk /> : null}
      </LabelText>
      <Switch
        style={{ marginLeft: 20, marginTop: 10 }}
        value={value || ''}
        onValueChange={(value) => setFieldValue(name, value)}
        {...rest}
      />
      <ErrorText>{error}</ErrorText>
    </FieldWrapper>
  );
};

export default connect(StyledSwitch);
