import React, { useState } from 'react';
import { NavigationContainer } from '@react-navigation/native';
import { ApolloProvider, createHttpLink, ApolloClient, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import * as Linking from 'expo-linking';
import { ThemeProvider } from 'styled-components/native';
import getSettings from './shared/settings';

import { StateContext } from './shared/state';
import { FabTheme } from './styles/theme';
import MenuDrawer from './components/MenuDrawer';

const { dgraph } = getSettings();

const linking = {
  prefixes: ['https://fabulist.greytip.biz', 'exps://fabulist.greytip.biz', prefix],
  config: {
    screens: {
      AuthSuccess: 'auth-success',
      NotFound: '*',
    },
  },
};

const prefix = Linking.makeUrl('/');

// linking={linking} fallback={<Text>Loading...</Text>}

export default function App() {
  const [user, setUser] = useState();
  const [modelListCursor, setModelListCursor] = useState(-1);

  const getApolloClient = () => {
    const httpLink = createHttpLink({
      uri: dgraph,
    });

    const authLink = setContext(async (_, { headers }) => {
      const token = user?.id_token;
      return {
        headers: {
          "X-Auth-Token": token ? `${token}` : "",
        },
      }
    });

    return new ApolloClient({
      link: authLink.concat(httpLink),
      cache: new InMemoryCache(),
      connectToDevTools: true,
    });
  }

  return (
    <StateContext.Provider value={{ user, setUser, modelListCursor, setModelListCursor }}>
      <ApolloProvider client={getApolloClient()}>
        <ThemeProvider theme={FabTheme}>
          <>
            <NavigationContainer>
              <MenuDrawer />
            </NavigationContainer>
          </>
        </ThemeProvider>

      </ApolloProvider>
    </StateContext.Provider>
  );
}
