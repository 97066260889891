import React from 'react';
import { SafeAreaView, StyleSheet, Text, TouchableOpacity } from 'react-native';
import { HeadingWrapper, Chevron } from '../../styles/components'

const AdminScreen = (props) => {
  const Content = ({name, screen}) => {
    const openContent = (screen, title) => {
      props.navigation.navigate(screen, { title });
    }
    return (
      <TouchableOpacity style={styles.itemContainer} onPress={() => openContent(screen, name)}>
        <HeadingWrapper>
          <Text style={styles.item}>{name}</Text>
        </HeadingWrapper>
        <Chevron />
      </TouchableOpacity>
    )
  }

  return (
    <SafeAreaView style={styles.container}>
      <Content name='Schema' screen='SchemaScreen'/>
      <Content name='System Maintenance' screen='SystemMaintenanceScreen'/>
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: { margin: 5, flex: 1, padding: 16, paddingTop: 30 },

  itemContainer: {
    flexDirection: "row",
    alignItems: 'center',
    backgroundColor: '#fff',
    marginBottom: 5,
  },

  item: {
    padding: 10,
    marginVertical: 8,
    fontSize: 18,
  },
});

export default AdminScreen;
