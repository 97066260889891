import {
  Field,
  Relation,
  ModelMetadata,
  CreatedDateAutoComputePolicy,
  BlankStringAutoComputePolicy,
} from '../model_defs';
import * as g from './graphql';
import * as f from './form';
import { createItemView, createListItemRenderer, createItemDetailView } from './view';

export const MODEL_NAME = 'Area'
const MODEL_PLURAL = 'Areas'

const module = new Relation(
  "id",
  "name",
  "queryModule",
  g.QUERY_MODULES,
  { dropdownWidth: '50%' }
);

const fields = [
  new Field('module', 'Module', { showInList: true, relation: module }),
  new Field('code', 'Code', { showInList: true }),
  new Field('name', 'Name', { showInList: true, flex: 2 }),
  new Field('strap_line', 'Strap Line', { showInList: true, flex: 6 }),
  new Field('description', 'Description', { autoComputePolicy: BlankStringAutoComputePolicy }),
  new Field('created', 'Created On', { autoComputePolicy: CreatedDateAutoComputePolicy }),
  new Field('is_published', 'Is Published'),
  new Field('icon', 'Icon'),
  new Field('remarks', 'Remarks', { autoComputePolicy: BlankStringAutoComputePolicy }),
];

export const MetadataInstance = new ModelMetadata(MODEL_NAME, {
  icon: 'pagelines',
  plural: MODEL_PLURAL,
  fetchQuery: g.QUERY_ALL,
  getItemQuery: g.QUERY_ITEM,
  addMutation: g.ADD_MUTATION,
  updateMutation: g.UPDATE_MUTATION,
  deleteMutation: g.DELETE_MUTATION,
  updateCache: g.updateCache,
  fields: fields,
  validationSchema: f.validationSchema,
  createModelForm: f.createModelForm,
  createItemView,
  createItemDetailView,
  createListItemRenderer
});
