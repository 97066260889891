import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import React from 'react';
import { ScrollView } from 'react-native';
import ModelMetaDataMap, { ADD_MODE } from '../models';
import { ErrorMessage } from '../styles/components';

const AddModelComponent = (props) => {
  const { model, onSave, height, width, formOptions } = props;
  const metadata = ModelMetaDataMap[model];

  if (metadata == undefined) {
    return <ErrorMessage message={`Misconfiguration: metadata not available for ${model}!`} />;
  }

  const { createModelForm, validationSchema } = metadata;
  const initialValues = metadata.getFlattenedValues({});
  const form = createModelForm(metadata, formOptions);

  const [mutate] = useMutation(metadata.addMutation, {
    update: metadata.updateCache,
    onCompleted: (data) => {
      console.log(`${metadata.name}: record added successfully!`);
      onSave();
    },
  });

  return (
    <ScrollView style={{ width: '100%' }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values) => {
          const record = metadata.getShapedValues(values, ADD_MODE);
          // console.log(record);
          mutate({ variables: { record } }).catch((e) => {
            console.log('${metadata.name}: add mutation failed', e);
          });
        }}
      >
        {form}
      </Formik>
    </ScrollView>
  );
};

export default AddModelComponent;
