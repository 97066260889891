const ENV = {
  dev: {
    apiUrl: 'https://fabulist2-api.gr8hr.biz/api/v1/',
    dgraph: 'https://lamentable-partner.us-west-2.aws.cloud.dgraph.io/graphql',
    auth0: {
      auth0Domain: 'dev-yaae2d-6.us.auth0.com',
      auth0ClientId: 'Qw5s7whEOyFxO77LjFNlIlk5JxT9vDS5',
      scopes: ['openid', 'profile', 'email', 'offline_access'],
      apiIdentifier: 'https://fabulist2-api',
    },
    algolia: {
      appID: '88X8PG98OE',
      searchAPIKey: '011dfa831bcf48f2dec4803e205a7504',
    },
  },
  staging: {
    apiUrl: 'https://fabulist2-api.gr8hr.biz/api/v1/',
    dgraph: 'https://lamentable-partner.us-west-2.aws.cloud.dgraph.io/graphql',
    auth0: {
      auth0Domain: 'dev-yaae2d-6.us.auth0.com',
      auth0ClientId: 'Qw5s7whEOyFxO77LjFNlIlk5JxT9vDS5',
      scopes: ['openid', 'profile', 'email', 'offline_access'],
      apiIdentifier: 'https://fabulist2-api',
    },
    algolia: {
      appID: '88X8PG98OE',
      searchAPIKey: '011dfa831bcf48f2dec4803e205a7504',
    },
  },
  prod: {
    apiUrl: 'https://fabulist2-api.dsgtkops.com/api/v1/',
    dgraph: 'https://fabulist.us-west-2.aws.cloud.dgraph.io/graphql',
    auth0: {
      auth0Domain: 'fabulist.us.auth0.com',
      auth0ClientId: 'kvG44LD1TC8h13qt5aP0nCJ6m8lnMFCm',
      scopes: ['openid', 'profile', 'email', 'offline_access'],
      apiIdentifier: 'https://fabulist2-api',
    },
    algolia: {
      appID: 'VI8W1G56UT',
      searchAPIKey: '62d80f5ec2c759623666481f33ae4277',
    },
  },
};

export default ENV;
