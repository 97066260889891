export const addToCache = (cache, query, newItem, variables = {}) => {
  try {
    const existingItems = cache.readQuery({ query, variables });
    const queryName = query.definitions[0].selectionSet.selections[0].name.value;
    const items = [...existingItems[queryName], newItem];
    cache.writeQuery({ query,  data: { [queryName]: items }, variables });
    const verifyItems = cache.readQuery({ query, variables });
    const found = verifyItems[queryName].find(it => it.id == newItem.id);
    console.log(found ? 'item was written to cache' : 'drat! not written to cache');
  } catch (error) {
    // ignore if schema is not in cache
    console.log(error);
  }
}
