import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connectInfiniteHits } from 'react-instantsearch-native';
import { FlatList, StyleSheet, TouchableOpacity, View } from 'react-native';
import { AREA, ASSET, FEATURE, MODULE } from '../models';
import Highlight from './Highlight';

const openViewPage = (model, id, navigation) => {
  if (model == ASSET) {
  }
  if (model == MODULE) {
    navigation.navigate('ModuleScreen', { moduleId: id });
  }
  if (model == AREA) {
    navigation.navigate('AreaScreen', { areaId: id });
  }
  if (model == FEATURE) {
    navigation.navigate('FeatureScreen', { featureId: id });
  }
};

const InfiniteHits = ({ hits, hasMore, refine, navigation, setShowHits }) => {
  const ListItem = ({ item }) => {
    const model = _.capitalize(item.type);
    const title = item.name;

    return (
      <TouchableOpacity
        onPress={() => {
          setShowHits(false);
          openViewPage(model, item.objectID, navigation);
        }}
      >
        <View style={styles.item}>
          <Highlight attribute='name' hit={item} />
        </View>
      </TouchableOpacity>
    );
  };

  return (
    <FlatList
      data={hits}
      keyExtractor={(item) => item.objectID}
      ItemSeparatorComponent={() => <View style={styles.separator} />}
      onEndReached={() => hasMore && refine()}
      renderItem={({ item }) => <ListItem item={item} />}
      style={{ flexGrow: 0, maxHeight: 300 }}
    />
  );
};

const styles = StyleSheet.create({
  container: { flex: 1 },
  separator: { borderBottomWidth: 1, borderColor: '#ddd' },
  item: { padding: 10, flexDirection: 'column' },
  titleText: { fontWeight: 'bold' },
});

InfiniteHits.propTypes = {
  hits: PropTypes.arrayOf(PropTypes.object).isRequired,
  hasMore: PropTypes.bool.isRequired,
  refine: PropTypes.func.isRequired,
};

export default connectInfiniteHits(InfiniteHits);
