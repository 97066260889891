import { FontAwesome5 } from '@expo/vector-icons';
import React from 'react';
import { Button } from 'react-native-elements';
import * as Yup from "yup";
import { WebContainer } from '../../components';
import StyledSwitch from '../../components/StyledSwitch';
import TextInput from '../../components/TextInput';
import { FontView, Form, IconWrapper, StyledModelPicker } from '../form_utils';

export const createModelForm = (metadata) => {
  return (formikProps) => {
    const { handleSubmit, values, errors, isValid } = formikProps;

    return (
      <Form style={{ padding: 10 }}>
        <StyledModelPicker label="Module" name="module" metadata={metadata} />
        <TextInput label="Code" name="code" maxLength={50} autocompletetype="off" autoCapitalize="characters" metadata={metadata}/>
        <TextInput label="Name" name="name" maxLength={200} autoCapitalize="words" metadata={metadata}/>
        <IconWrapper>
            <TextInput label="Icon" name="icon" maxLength={25} metadata={metadata}/>
            <FontView><FontAwesome5 name={values.icon} size={64} color="black" /></FontView>
        </IconWrapper>
        <TextInput label="Strap Line" name="strap_line" maxLength={250} metadata={metadata}/>
        <TextInput label="Description" name="description" multiline numberOfLines={4} metadata={metadata}/>
        <TextInput label="Remarks" name="remarks" multiline numberOfLines={4} metadata={metadata}/>
        <StyledSwitch label="Is Published?" name="is_published" metadata={metadata}/>
        <WebContainer>
          <Button disabled={!isValid} onPress={handleSubmit} title="SUBMIT" />
        </WebContainer>
      </Form>
    );
  }
}

export const validationSchema = Yup.object().shape({
  module: Yup.string().label('Module').required(),
  code: Yup.string().label('Code').required(),
  name: Yup.string().label('Name').required(),
  description: Yup.string().label('Description').required(),
  is_published: Yup.boolean().label('Is Published').required(),
})
