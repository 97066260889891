import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { connectHighlight } from 'react-instantsearch-native';
import { Text, View } from 'react-native';
import { getModelIcon } from '../models';
import { SmallIcon } from '../styles/components';
import { FabTheme } from '../styles/theme';

const Highlight = ({ attribute, hit, highlight }) => {
  const highlights = highlight({
    highlightProperty: '_highlightResult',
    attribute,
    hit,
  });
  const model = _.capitalize(hit.type);
  const icon = getModelIcon(model);

  return (
    <View style={{ flexDirection: 'row', alignItems: 'center' }}>
      <SmallIcon name={icon} />
      <View>
        <Text style={{ color: FabTheme.colors.subText, marginTop: 4 }}>{model}</Text>
        <Text>
          {highlights.map(({ value, isHighlighted }, index) => {
            const style = {
              backgroundColor: isHighlighted ? FabTheme.colors.highlight : 'transparent',
              color: FabTheme.colors.text,
              fontSize: 18,
            };
            return (
              <Text key={index} style={style}>
                {value}
              </Text>
            );
          })}
        </Text>
      </View>
    </View>
  );
};

Highlight.propTypes = {
  attribute: PropTypes.string.isRequired,
  hit: PropTypes.object.isRequired,
  highlight: PropTypes.func.isRequired,
};

export default connectHighlight(Highlight);
