import { gql } from '@apollo/client';
import { addToCache } from '../common-graphql';

const FeaturesFragment = gql`
  fragment FeaturesFragment on Feature {
    id
    code
    name
    strap_line
    description
    created
    modified
    is_published
    icon
    remarks
    benefit
    advantage
    frequency
    commonality
    priority
    impact
  }
`;

const FeatureAreaFragment = gql`
  fragment FeatureAreaFragment on Feature {
    area {
      id
      code
      name
      module {
        id
        code
        name
      }
    }
  }
`;

export const QUERY_ALL = gql`
  query AllFeatures {
    queryFeature(order: {asc: name}) {
      ...FeaturesFragment
      ...FeatureAreaFragment
    }
  }
  ${FeaturesFragment}
  ${FeatureAreaFragment}
`;

export const QUERY_BY_MODULE = gql`
  query FeaturesByModule($id: ID!) {
      queryFeature @cascade  {
        id
        code
        name
        strap_line
        description
        created
        modified
        is_published
        frequency
        commonality
        priority
        impact
        area {
          id
          name
          module(filter: { id: [$id] }) {
            id
          }
        }
      }
  }
`;

export const ADD_MUTATION = gql`
  mutation addFeature($record: AddFeatureInput!) {
    addFeature(input: [$record]) {
        feature {
        ...FeaturesFragment
        ...FeatureAreaFragment
      }
    }
  }
  ${FeaturesFragment}
  ${FeatureAreaFragment}
`;

export const UPDATE_MUTATION = gql`
  mutation UpdateFeature($patch: UpdateFeatureInput!) {
    updateFeature(input: $patch) {
        feature {
        ...FeaturesFragment
        ...FeatureAreaFragment
      }
    }
  }
  ${FeaturesFragment}
  ${FeatureAreaFragment}
`;

export const DELETE_MUTATION = gql`
mutation deleteFeature($filter: FeatureFilter!) {
  deleteFeature(filter: $filter) {
    msg
  }
}
`;

export const QUERY_ITEM = gql`
  query ReadFeature($id: ID!) {
    queryFeature(filter: {id: [$id]}) {
      ...FeaturesFragment
      ...FeatureAreaFragment
    }
  }
  ${FeaturesFragment}
  ${FeatureAreaFragment}
`;

export const QUERY_AREAS = gql`
  query AllAreas($filter: AreaFilter, $moduleFilter: ModuleFilter) {
    queryArea(filter: $filter, order: {asc: name}) @cascade {
      id
      name
      module(filter: $moduleFilter) {
        id
        name
      }
    }
  }
`;

export const updateCache = (cache, { data }) => {
  debugger;
  const newItem = data.addFeature.feature[0];
  const moduleId = newItem.area.module.id;
  addToCache(cache, QUERY_ALL, newItem);
  addToCache(cache, QUERY_BY_MODULE, newItem, { id: moduleId });
}
