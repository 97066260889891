import { useMutation } from '@apollo/client';
import { FontAwesome5 } from '@expo/vector-icons';
import React from 'react';
import { Alert } from 'react-native';
import { FloatingAction } from 'react-native-floating-action';
import { deleteIndexRequest } from '../search/searchIndexApi';
import { useAuthenticatedAxios } from '../shared/api';

const EDIT_ICON = 'edit';
const DELETE_ICON = 'trash-alt';

const actions = [
  {
    text: 'Edit',
    icon: <FontAwesome5 name={EDIT_ICON} size={20} color='white' />,
    name: 'edit',
    position: 1,
  },
  {
    text: 'Delete',
    icon: <FontAwesome5 name={DELETE_ICON} size={20} color='white' />,
    name: 'delete',
    position: 2,
  },
];

const DialogBox = (model, onDelete) =>
  Alert.alert(
    'Delete',
    `Are you sure you want to delete the ${model}?`,
    [
      {
        text: 'Cancel',
        onPress: () => console.log('Delete Cancelled'),
      },
      { text: 'OK', onPress: () => onDelete() },
    ],
    { cancelable: false },
  );

const FloatingActionButtons = ({ item, navigation, metadata }) => {
  const model = metadata.name;
  const [
    {
      data: postData,
      loading: postLoading,
      error: postError,
      response: postResponse,
    },
    executePost,
  ] = useAuthenticatedAxios(deleteIndexRequest());

  const [deleteEntity, { result }] = useMutation(metadata.deleteMutation);

  const onEdit = () => {
    navigation.navigate(metadata.editScreen, { mode: 'edit', data: item, model });
  };

  const onDelete = () => {
    console.log(`deleting ${model}: ${item.id}`);
    deleteEntity({
      variables: { filter: { id: [item.id] } },
      update: (cache) => {
        console.log(`updating cache after delete for id: ${item.id}!`);
        const existingItems = cache.readQuery({ query: metadata.fetchQuery });
        const evicted = cache.evict({ id: item.id });
        const newItems = existingItems[metadata.queryAll].filter((t) => t.id !== item.id);
        let data = {};
        data[metadata.queryAll] = newItems;
        cache.writeQuery({
          query: metadata.fetchQuery,
          data,
        });
      },
      onCompleted: (data) => {
        console.log(`${metadata.name} deleted successfully!`);
      },
    });
    executePost({
      data: {
        objectId: item.id,
      },
    });
    if(postError){
      console.log(postError);
    }
    navigation.goBack();
  };

  return (
    <FloatingAction
      actions={actions}
      distanceToEdge={{ position: 'absolute', vertical: 10, horizontal: 10 }}
      position='right'
      onPressItem={(name) => {
        if (name === 'edit') {
          onEdit();
        } else if (name === 'delete') {
          DialogBox(model, onDelete);
        }
      }}
    />
  );
};

export default FloatingActionButtons;
