import { StyleSheet, Dimensions } from 'react-native';
import { Typography, Spacing, Colors } from './index';
const window = Dimensions.get('window');
const screenHeight = Math.round(Dimensions.get('window').height);

export default StyleSheet.create({
  container: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  homeTop: {
  },

  companyTextInput: {
    ...Spacing.container,
    paddingLeft: 20,
  },

  companySearchButtonContainer: {
    flex:0.25,
    alignItems: 'flex-end',
  },

  companySearchButton: {
    padding: Spacing.smallSpace,
    backgroundColor: Colors.buttonBackground,
    borderRadius: 25,
  },

  bottomSpacing: {
    marginBottom: Spacing.largeSpace,
  },

  postBtn: {
    padding: 10,
    margin: 5,
    borderColor: Colors.lightBlue,
    borderWidth: 0.4,
    backgroundColor: '#02b4e5',
    color: '#FFF',
  },

  flexRow: {
    flexDirection:'row',
  },

  PickerInputLeft: {
    flex:0.4,
    alignItems: 'flex-start',
  },

  autoSignOnScreen: {
    flex: 1,
    backgroundColor: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
  },

  splashScreenStyles: {
    flex: 1,
    backgroundColor: '#E9F7FF',
    alignItems: 'center',
    justifyContent: 'center',
  },

  splashScreenBackground: {
    width: '100%',
    height: '100%',
  },

  splashScreenText: {
    fontSize: 40,
    marginTop: 20,
    fontFamily: 'OpenSans-SemiBold',
  },

  splashScreenLogo: {
    flex: 1,
    alignItems: 'center',
    justifyContent: 'center',
  },

  inputLeft: {
    flex:1,
    alignItems: 'flex-start',
  },

  inputRight: {
    flex:1,
    alignItems: 'flex-end',
  },

  inputRightWidth: {
    width: 200,
  },

  input: {
    ...Typography.inputText,
    height: 50,
    borderColor: '#ccc',
    borderBottomWidth: 1,
    marginHorizontal: 10,
    marginVertical: 5,
    width: window.width - 90,
  },

  logo: {
    alignSelf: 'center',
  },

  centerAlignPadding: {
    ...Spacing.centerAlign,
    padding: Spacing.largeSpace,
  },

  TaxesHeadingText: {
    ...Spacing.centerAlign,
    ...Typography.cardCenterHeadings,
    paddingTop: 5,
  },

  button:{
    alignItems:'center',
    justifyContent:'center',
    height:50,
    backgroundColor: '#04cef7',
    borderRadius: 25,
    marginTop: 20,
    width:window.width - 90,
  },

  card:{
    marginLeft: 20,
    marginRight: 20,
    backgroundColor:'transparent',
    padding: 10,
    flexDirection:'row',
    borderRadius:30,
    width: 400,
  },

  AuthPage: {
    flex:1,
    backgroundColor:'#F8F9FC',
  },

  mainWelcomeText: {
    fontSize: 36,
    paddingLeft: 20,
    marginTop: 60,
    paddingBottom: 10,
    fontFamily: Typography.fontfamilyBold,
  },

  welcomeDescription: {
    fontSize: 24,
    paddingLeft: 20,
    paddingBottom: 10,
    fontFamily: Typography.fontFamilySemiBold,
  },

  welcomeHelpText: {
    fontSize: 16,
    paddingLeft: 20,
    fontFamily: Typography.fontFamilyNormal,
  },

  butonContainer: {
    justifyContent: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    marginBottom: 20,
  },

  buttonText: {
    fontSize: 18,
    color: Colors.whiteText,
    fontFamily: Typography.fontFamilyNormal,
  },

  scrollBottomMargin: {
    marginBottom: '60%',
  },

  cardLeftContent: {
    flex: 1,
  },

  taxRightContent: {
    flex:1,
    right: 0,
    alignItems: 'flex-end',
  },

  textIcon: {
    paddingLeft: 8,
  },

  cardTableContainer: {
    flex: 1,flexDirection:'row',
  },

  cardRightContent: {
    flex:1,
    right: 0,
    alignItems: 'flex-end',
    alignSelf: 'flex-end',
  },

  homepageCardContainer: {
    flex: 1,
    flexDirection:'row',
    padding: 10,
  },

  homepageCardIcon: {
    padding: 5,
    alignItems:'flex-start',
  },

  item:{
    padding: 10,
    alignSelf: 'flex-start',
    flexDirection : 'row',
  },

  sideMenuItem:{
    padding: 10,
    alignSelf: 'flex-start',
    flexDirection : 'row',
    width: '100%',
  },

  logoutTab: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection : 'row',
    width: '100%',
  },

  logoutSeparatorLine: {
    borderBottomColor: 'black',
    borderBottomWidth: 0.6,
    marginTop: 20,
  },

  logoutText: {
    ...Typography.sideMenuText,
    paddingLeft: 5,
  },

  infoContent:{
    paddingTop: 5,
    alignItems:'flex-start',
    paddingLeft: 40,
    fontFamily: Typography.fontFamilyNormal,
  },

  cardItem:{
    paddingLeft: 10,
    alignSelf: 'flex-start',
    flexDirection : 'row',
  },

  fullWidthCardHeader: {
    paddingLeft: 10,
    alignSelf: 'flex-start',
    flexDirection : 'row',
    width: 200,
  },

  employeeDetailCard: {
    marginTop: 45,
    padding:30,
    alignItems: 'center',
    fontSize: 20,
  },

  taxesHeadingContainer: {
    paddingLeft: 10,
    alignSelf: 'flex-start',
    flexDirection : 'row',
    paddingBottom: 18,
  },

  payslipCardItem:{
    padding: 10,
    alignSelf: 'flex-start',
    flexDirection : 'row',
  },

  homepageCompanyName: {
    position: 'absolute',
    flexDirection: 'column',
    justifyContent: 'center',
    alignContent: 'center',
    alignSelf: 'center',
    maxWidth: window.width - 20,
    marginTop: '10%',
  },

  companyInfoContent:{
    alignItems:'flex-start',
    paddingLeft: Spacing.largeSpace,
    fontFamily: Typography.fontFamilyNormal,
    marginVertical: 10,
  },

  companyCardContainer: {
    marginVertical: 10,
    alignItems:'flex-start',
    paddingLeft: Spacing.largeSpace,
    fontFamily: Typography.fontFamilyNormal,
    ...Spacing.centerAlign,
  },

  iconContent:{
    alignItems:'flex-end',
    paddingRight:5,
  },

  icon:{
    padding: Spacing.largeSpace,
    alignItems:'flex-start',
  },

  info:{
    fontSize:18,
    color: '#000',
  },

  image:{
    width:70,
    height:70,
    borderRadius:45,
  },

  name:{
    fontSize:24,
    flex:1,
    alignSelf:'center',
    fontWeight:'bold',
    fontFamily: Typography.fontfamilyBold,
  },

  subText:{
    fontSize:12,
    flex:1,
    alignSelf:'center',
    fontFamily: Typography.fontFamilyNormal,
  },

  fontWhite14: {
    color: Colors.whiteText,
    fontSize: 14,
    fontFamily: Typography.fontFamilyNormal,
  },

  fontWhite20: {
    alignSelf: 'center',
    color: Colors.whiteText,
    fontSize: 20,
    fontFamily: Typography.fontfamilyBold,
  },

  mainPageHeaderImage: {
    marginTop: -60,
    height: 140,
    alignSelf: 'center',
  },

  headerContentPosition: {
    position: 'absolute',
    flexDirection: 'column',
    alignSelf: 'center',
    marginTop: '15%',
  },

  helloUser: {
    marginTop: 0,
    padding:10,
    alignItems: 'center',
  },

  helloCard:{
    shadowColor:  Colors.shadowColor,
    shadowOffset: {
      width: 0,
      height: 6,
    },
    shadowOpacity: 0.37,
    shadowRadius: 7.49,
    elevation: 12,
    marginTop: -70,
    marginLeft: 20,
    marginRight: 20,
    backgroundColor: Colors.paleBlue,
    padding: 10,
    flexDirection:'row',
    justifyContent: 'center',
    borderRadius:10,
    width: window.width - 50,
  },

  fontBlack22:{
    alignSelf: 'center',
    textAlign: 'center',
    justifyContent: 'center',
    fontSize:22,
    color:'#000',
    fontFamily: Typography.fontFamilySemiBold,
  },

  font18Black: {
    fontSize:18,
    fontFamily: Typography.fontFamilyNormal,
    color: '#000',
  },

  lineSeparator: {
    borderBottomColor: 'black',
    borderBottomWidth: 0.5,
    marginTop: 20,
    marginLeft: 40,
    marginRight: 40,
  },

  profileImage: {
    height: 80,
    width: 80,
    marginBottom: 10,
    borderRadius: 100,
  },

  sideMenuImage: {
    justifyContent: 'center',
    alignSelf: 'center',
    alignContent: 'center',
    alignItems: 'center',
    marginTop: 50,
  },

  detailsCard:{
    padding:30,
    marginTop: 30,
    alignItems: 'center',
    fontSize: 20,
  },

  topPayslipCard:{
    padding:30,
    alignItems: 'center',
    fontSize: 20,
    marginTop: 60,
  },

  companyNote: {
    lineHeight: 25,
    maxWidth: window.width - 120,
    fontFamily: Typography.fontFamilyNormal,
  },

  payslipTableHeadingGreen: {
    ...Typography.netPayText,
    padding: Spacing.smallSpace,
  },

  payslipTableHeadingOrange: {
    ...Typography.deductionsText,
    padding: Spacing.smallSpace,
  },

  cardDetail:{
    shadowColor:  Colors.shadowColor,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.37,
    shadowRadius: 2.49,
    elevation: 7,
    marginTop: -70,
    marginLeft: 20,
    marginRight: 20,
    backgroundColor: Colors.whiteBackground,
    padding: 10,
    flexDirection:'row',
    fontSize: 20,
    borderRadius:10,
    width: window.width - 50,
  },

  companyListCard:{
    shadowColor:  Colors.shadowColor,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.37,
    shadowRadius: 2.49,
    elevation: 3,
    marginTop: 20,
    backgroundColor: Colors.whiteBackground,
    flexDirection:'row',
    padding: 10,
    borderRadius: 45,
    width: window.width - 70,
  },

  companyImg:{
    marginTop: 15,
    width: 50,
    height: 50,
    borderRadius:45,
  },

  centerAlign: {
    alignSelf: 'center',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection : 'row',
  },

  payslipHeaderText: {
    position: 'absolute',
    flexDirection: 'column',
    alignSelf: 'center',
    color: Colors.whiteText,
    fontSize: 20,
    fontWeight: 'bold',
    marginTop: '35%',
  },

  appVersionText: {
    alignSelf: 'center',
    justifyContent: 'center',
    fontFamily: Typography.fontFamilyNormal,
    fontStyle: 'italic',
    paddingTop: 8,
  },

  footerCompanyName: {
    alignSelf: 'center',
    justifyContent: 'center',
    paddingTop: 8,
    paddingBottom: 8,
  },

  smallButton: {
    marginTop:10,
    height:35,
    width:70,
    margin: 5,
    padding:10,
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius:30,
    backgroundColor: '#E2F4FF',
  },
  smallButtonText:{
    marginTop: -3,
    color: '#24A7F8',
    fontSize: 14,
    fontFamily: Typography.fontFamilyNormal,
  },

  payslipCardDetail:{
    shadowColor:  Colors.shadowColor,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.37,
    shadowRadius: 2.49,
    elevation: 7,
    marginTop: -70,
    marginLeft: 20,
    marginRight: 20,
    backgroundColor: Colors.whiteBackground,
    padding: 10,
    flexDirection:'column',
    fontSize: 20,
    borderRadius:10,
    width: window.width - 50,
  },

  payslipInfoCardDetail:{
    shadowColor:  Colors.shadowColor,
    shadowOffset: {
      width: 0,
      height: 3,
    },
    shadowOpacity: 0.37,
    shadowRadius: 2.49,
    elevation: 7,
    marginTop: -70,
    marginLeft: 20,
    marginRight: 20,
    backgroundColor:'#CDE8FC',
    padding: 15,
    flexDirection:'row',
    fontSize: 20,
    borderRadius:10,
    width: window.width - 50,
    borderWidth: 1,
    borderColor: '#24A7F8',
  },

  payslipHeaderContent:{
    marginTop: '-10%',
    alignItems: 'center',
  },

  iconPadding10:{
    padding: 10,
    alignItems:'flex-start',
  },

  payslipInfoContent:{
    alignItems:'flex-start',
    paddingLeft: Spacing.smallSpace,
  },
  iconContentWidth20:{
    width: Spacing.largeSpace,
    alignItems:'flex-end',
    paddingLeft:12,
  },

  font14Bold: {
    fontSize: 14,
    fontFamily: Typography.fontfamilyBold,
  },

  loadingIndicatorStyles: {
    flex: 1,
    padding: 20,
    color: '#000',
    alignItems: 'center',
    alignSelf: 'center',
  },

  payslipContent: {
    fontFamily: Typography.fontFamilyNormal,
    padding: 10,
    fontSize: 14,
  },

  hrLine: {
    borderBottomWidth: 1,
    borderBottomColor: '#000',
  },

  downloadBtnContainer: {
    alignSelf: 'flex-end',
    position: 'absolute',
    marginTop: screenHeight - 20,
  },

  downloadBtn:{
    height: 50,
    width: 50,
    borderRadius: 200,
    position: 'absolute',
    zIndex: 99,
    bottom: 20,
    right: 20,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor:'#00b5ec',
  },

  payslipScrollMargin: {
    flex: 1,
    marginBottom: '70%',
  },

  textStyle: {
    margin: 24,
    fontSize: 25,
    fontWeight: 'bold',
    textAlign: 'center',
  },
  pickerStyle: {
      height: 150,
      width: '80%',
      color: '#344953',
      justifyContent: 'center',
  },
  drawerIconPosition: {
    paddingTop: 0,
    paddingLeft: 15,
    paddingRight: 15,
  },
  drawerIconStyles: {
    height: 35,
    width: 35,
  },
  loadingIcon: {
    padding: 20,
  },
  defaultFlex: {
    flex: 1,
  },
});
