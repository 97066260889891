import React, { useContext } from 'react';
import { SafeAreaView, StyleSheet, View, Text, Button } from 'react-native';
import { LinearGradient } from 'expo-linear-gradient';
import { clearUserInfo } from '../shared/auth'
import { StateContext } from '../shared/state'
import {
    ActivityIndicator,
    SectionWrapper,
    HeadingWrapper,
    Heading1,
    BodyText,
    SubText,
    MediumIcon
} from '../styles/components'

const LogoutButton = ({ onPress }) => (
    <View style={styles.logout}>
        <Button title="Logout" onPress={onPress} />
    </View>
)

const ProfileScreen = () => {
    const { user, setUser } = useContext(StateContext);

    const logout = async () => {
        clearUserInfo();
        setUser({});
    }

    return (
        <SafeAreaView style={styles.container}>
            <HeadingWrapper style={{ marginBottom: 20 }}>
                <MediumIcon name={"asterisk"} />
                <SectionWrapper>
                    <Heading1>{user?.name}</Heading1>
                    <BodyText>{user?.email}</BodyText>
                </SectionWrapper>
            </HeadingWrapper>
            <LogoutButton onPress={logout} />
        </SafeAreaView>
    )
}

const styles = StyleSheet.create({
    container: { backgroundColor: '#fff', flex: 1, },
    profile: { margin: 20 },
    name: { fontSize: 20, fontWeight: 'bold' },
    email: { fontSize: 16, marginVertical: 20 },
    logout: {
        borderRadius: 5,
        alignSelf: 'center',
        width: 120,
        marginTop: 80,
        overflow: 'hidden'
    }
})

export default ProfileScreen