import React from 'react';
import { ListItem } from 'react-native-elements';
import ItemDetailComponent from './ItemDetailComponent';
import ItemViewComponent from './ItemViewComponent';

export const createItemView = (item, metadata, navigation) => {
  return (props) => {
    return <ItemViewComponent id={item.id} metadata={metadata} navigation={navigation} />;
  };
};

export const createItemDetailView = (item, metadata) => {
  return (props) => {
    return <ItemDetailComponent id={item.objectID} metadata={metadata} />;
  };
};

export const createListItemRenderer = (onPress) => {
  return ({ item }) => {
    return (
      <ListItem key={item.id} onPress={() => onPress(item)} bottomDivider>
        <ListItem.Content>
          <ListItem.Title style={{ fontSize: 18 }}>{item.name}</ListItem.Title>
        </ListItem.Content>
        <ListItem.Chevron />
      </ListItem>
    );
  };
};
