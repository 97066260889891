import {
  Field,
  Relation,
  ModelMetadata,
  CreatedDateAutoComputePolicy,
  ModifiedDateAutoComputePolicy,
  BlankStringAutoComputePolicy,
} from '../model_defs';
import * as g from './graphql';
import * as f from './form';
import { createItemView, createListItemRenderer, createItemDetailView } from './view';

export const MODEL_NAME = 'Feature'
const MODEL_PLURAL = 'Features'

const area = new Relation(
  "id",
  "name",
  "queryArea",
  g.QUERY_AREAS,
  { dropdownWidth: '50%' }
);

const fields = [
  new Field('area', 'Area', {
    showInList: true,
    relation: area,
    columnDef: {
      editable: true,
      rowGroup: true,
      hide: true,
    }
  }),
  new Field('code', 'Code', { showInList: true, flex: 1, columnDef: { editable: true }, quickAdd: true }),
  new Field('name', 'Name', { showInList: true, flex: 2, columnDef: { editable: true }, quickAdd: true }),
  new Field('strap_line', 'Strap Line', { flex: 5, quickAdd: true } ),
  new Field('description', 'Description', { default: '', quickAdd: true }),
  new Field('created', 'Created On', { autoComputePolicy: CreatedDateAutoComputePolicy }),
  new Field('modified', 'Last Modified On', { autoComputePolicy: ModifiedDateAutoComputePolicy, flex: 3 }),
  new Field('is_published', 'Is Published', { default: true } ),
  new Field('icon', 'Icon', { default: 'asterisk' }),
  new Field('remarks', 'Remarks', { default: '' }),
  new Field('benefit', 'Benefit', { default: '' }),
  new Field('advantage', 'Advantage', { default: '' }),
  new Field('frequency', 'Frequency', {  default: 1, showInList: true, flex: 0.5 }),
  new Field('commonality', 'Commonality', { default: 1, showInList: true, flex: 0.5, columnDef: { hide: true }  }),
  new Field('priority', 'Priority', { default: 1, showInList: true, flex: 0.5, columnDef: { hide: true }  }),
  new Field('impact', 'Impact', { default: 1, showInList: true, columnDef: { hide: true } }),
];

export const MetadataInstance = new ModelMetadata(MODEL_NAME, {
  icon: 'leaf',
  plural: MODEL_PLURAL,
  fetchQuery: g.QUERY_ALL,
  getItemQuery: g.QUERY_ITEM,
  addMutation: g.ADD_MUTATION,
  updateMutation: g.UPDATE_MUTATION,
  deleteMutation: g.DELETE_MUTATION,
  updateCache: g.updateCache,
  fields: fields,
  validationSchema: f.validationSchema,
  createModelForm: f.createModelForm,
  createItemView,
  createItemDetailView,
  createListItemRenderer
});
