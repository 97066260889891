import React from 'react';
import { Button, StyleSheet, Text, View } from 'react-native';
import { reIndexRequest } from '../../search/searchIndexApi';
import { useAuthenticatedAxios } from '../../shared/api';
import { ActivityIndicator, ErrorMessage } from '../../styles/components';

const SUCCESS = 'Search index rebuild initiated successfully!';
const FAILURE = 'Sorry, we are facing some server error at the moment!';

const SystemMaintenanceScreen = (props) => {
  const [{ data, loading, error, response }, execute] = useAuthenticatedAxios(
    reIndexRequest()
  );

  const onPress = () => execute();

  if (loading) {
    return <ActivityIndicator size='small' />;
  }

  // const { code } = response || {};

  return (
    <View style={styles.container}>
      <Button title='Rebuild Search Index' onPress={onPress} />
      {data ? <Text>{SUCCESS}</Text> : null}
      {error ? <ErrorMessage>{FAILURE}</ErrorMessage> : null}
    </View>
  );
};

const styles = StyleSheet.create({
  button: { backgroundColor: '#78B7BB', borderRadius: 2 },

  response: {
    padding: 20,
    marginTop: 20,
    fontWeight: 'bold',
  },

  container: {
    backgroundColor: '#fff',
    margin: 5,
    padding: 16,
    flex: 1,
    alignItems: 'flex-start',
  },
});

export default SystemMaintenanceScreen;
