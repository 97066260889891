import React, { useState } from 'react';
import SearchBox from './SearchBox';
import InfiniteHits from './InfiniteHits';
import styled from 'styled-components/native';
import { InstantSearch } from 'react-instantsearch-native';
import algoliasearch from 'algoliasearch/lite';
import getSettings from '../shared/settings';

const { algolia: { appID, searchAPIKey } } = getSettings();

const searchClient = algoliasearch(
    appID,
    searchAPIKey
);

const StyledSearch = styled.View`
    background-color: #fff;
    margin: 16px;
`;

const Search = ({ navigation }) => {
    const [showHits, setShowHits] = useState(false);
    return (
      <StyledSearch>
        <InstantSearch searchClient={searchClient} indexName="dev_entities">
          <SearchBox setShowHits={setShowHits} showHits={showHits}/>
          {showHits ? 
            <InfiniteHits navigation={navigation} setShowHits={setShowHits}/>
            : null}
        </InstantSearch>
      </StyledSearch>
    );
};

export default Search;
