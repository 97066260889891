import React from 'react'
import { compose } from "recompose";
import styled from 'styled-components/native'
import { Platform, StyleSheet, TextInput, Switch, View, Text, TouchableOpacity, Button, Image } from 'react-native'
import { FontAwesome5 } from '@expo/vector-icons';
import ModelPicker from '../components/ModelPicker';
import {
  handleTextInput,
  withInputTypeProps,
  withNextInputAutoFocusForm,
  withNextInputAutoFocusInput
} from "react-native-formik";

const MyInput = compose(
  handleTextInput,
  withNextInputAutoFocusInput,
)(TextInput);

export const Form = withNextInputAutoFocusForm(View);

export const StyledModelPicker = styled(ModelPicker)`
  border: 0;
  backgroundColor: transparent;
  borderBottomWidth: 1px;
  borderColor: #bcbcbc;
`;

export const StyledInput = styled(MyInput)`
  minHeight: 40px;
  padding: 10px;
  fontSize: 18px;
  borderBottomWidth: 1px;
  borderColor: #bcbcbc;
`;

const ItemView = styled.View`
  padding: 15px;
  marginVertical: 1px;
  borderBottomWidth: 1px;
  borderColor: #ccc;
  backgroundColor: white;
  flex-direction: row;
  justifyContent: space-between;
`;

const ChevronView = styled.View`
  justifyContent: center;
  alignItems: center;
  marginRight: 5;
  alignSelf: flex-end;
`;

export const defaultItemRenderer = (onPress, metadata) => {
  return ({ item }) => {
    const firstKey = Object.keys(item).filter(k => !k.startsWith("_"))[0]
    const key = metadata?.nativeListField || firstKey;
    return (
      <TouchableOpacity onPress={() => onPress(item)}>
        <ItemView>
          <Text style={{ fontSize: 18 }}>{item[key]}</Text>
          <ChevronView>
            <FontAwesome5 name="chevron-right" size={16} color="#BBB" />
          </ChevronView>
        </ItemView>
      </TouchableOpacity>
    )
  };
}

export const IconWrapper = styled.View`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const FontView = styled.View`
  margin-left: 25px;
`
