export const container = {
  flex: 1,
  alignItems: 'center',
  justifyContent: 'center',
};

export const centerAlign = {
  justifyContent: 'center',
  alignContent: 'center',
  alignSelf: 'center',
};

export const smallSpace = 10;
export const largeSpace = 20;
export const flexDefault = 1;
