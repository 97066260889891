import React from 'react';
import { View, TouchableOpacity, Image } from 'react-native';
import { DrawerActions } from '@react-navigation/native';
import burgerMenuImg from './../assets/images/drawer.png';
import styled from 'styled-components/native';

const ButtonView = styled.View`
    backgroundColor: ${props => props.theme.colors.primary};
    marginLeft: 5px;
    marginTop: 5px;
`;

const BurgerMenuButton = ({ navigation }) => {
    return (
        <TouchableOpacity onPress={() => navigation.dispatch(DrawerActions.toggleDrawer())}>
            <ButtonView>
                <Image
                    style={{ width: 40, height: 40 }}
                    source={burgerMenuImg}
                />
            </ButtonView>
        </TouchableOpacity>
    )
}

export default BurgerMenuButton