import { AsyncStorage } from 'react-native';

async function getItem(key) {
  return await AsyncStorage.getItem(key);
}

async function setItem(key, value) {
  AsyncStorage.setItem(key, value);
}

async function deleteItem(key) {
  AsyncStorage.removeItem(key);
}

export { getItem, setItem, deleteItem }
