import React from 'react';
import { View, Text } from 'react-native';



const NotFoundScreen = (props) => {
  const { route } = props;

  console.log(route);

  return (
    <View>
      <Text>Screen not found!</Text>
    </View>
  )
}

export default NotFoundScreen;

