import React from 'react';
import { Button } from 'react-native-elements';
import * as Yup from "yup";
import { WebContainer } from '../../components';
import StyledSwitch from '../../components/StyledSwitch';
import TextInput from '../../components/TextInput';
import { Form, StyledModelPicker } from '../form_utils';

export const createModelForm = (metadata, formOptions = {}) => {
  return (formikProps) => {
    const { handleChange, handleBlur, handleSubmit, values, errors, setFieldValue, touched, isValid } = formikProps;

    return (
      <Form style={{ padding: 10 }}>
        <StyledModelPicker label="Area" name="area" metadata={metadata} formOptions={formOptions} />
        <TextInput label="Code" name="code" maxLength={50} autocompletetype="off" autoCapitalize="characters" metadata={metadata}/>
        <TextInput label="Name" name="name" maxLength={200} autoCapitalize="words" metadata={metadata}/>
        <TextInput label="Strap Line" name="strap_line" maxLength={250} metadata={metadata}/>
        <TextInput label="Description" name="description" multiline numberOfLines={4} metadata={metadata}/>
        {metadata.mustShowField('is_published', formOptions) ?
          <StyledSwitch label="Is Published?" name="is_published" metadata={metadata}/> : null
        }
        {metadata.mustShowField('remarks', formOptions) ?
          <TextInput label="Remarks" name="remarks" multiline numberOfLines={4} metadata={metadata}/> : null
        }
        {metadata.mustShowField('benefit', formOptions) ?
          <TextInput label="Benefit" name="benefit" multiline numberOfLines={4} metadata={metadata}/> : null
        }
        {metadata.mustShowField('advantage', formOptions) ?
          <TextInput label="Advantage" name="advantage" multiline numberOfLines={4} metadata={metadata}/> : null
        }
        {metadata.mustShowField('frequency', formOptions) ?
          <TextInput label="Frequency" name="frequency" metadata={metadata}/> : null
        }
        {metadata.mustShowField('commonality', formOptions) ?
          <TextInput label="Commonality" name="commonality" metadata={metadata}/> : null
        }
        {metadata.mustShowField('priority', formOptions) ?
          <TextInput label="Priority" name="priority" metadata={metadata}/> : null
        }
        {metadata.mustShowField('impact', formOptions) ?
          <TextInput label="Impact" name="impact" metadata={metadata}/> : null
        }
        <WebContainer>
          <Button disabled={!isValid} onPress={handleSubmit} title="SUBMIT" />
        </WebContainer>
      </Form>
    );
  }
}

export const validationSchema = Yup.object().shape({
  area: Yup.string().label('Area').required(),
  code: Yup.string().label('Code').required(),
  name: Yup.string().label('Name').required(),
  strap_line: Yup.string().label('Strap Line'),
  description: Yup.string().label('Description').required(),
  is_published: Yup.boolean().label('Is Published'),
  frequency: Yup.number().label('Frequency').min(0).max(100),
  commonality: Yup.number().label('Commonality').min(0).max(10),
  priority: Yup.number().label('Priority').min(0).max(10),
  impact: Yup.number().label('Impact').min(0).max(10),
})

