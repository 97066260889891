import { useMutation } from '@apollo/client';
import React, { useRef } from 'react';
import { View } from 'react-native';
import { EDIT_MODE, useColumnDefs, useSafeQuery } from '../models';
import { MetadataInstance as metadata } from '../models/feature';
import { QUERY_BY_MODULE } from '../models/feature/graphql';
import { ActivityIndicator, ErrorMessage } from '../styles/components';
import ModelGrid, { getExpandButtonColDef } from './ModelGrid';

const FeaturesGrid = ({ id, onView }) => {
  const variables = { area: { filter: {}, moduleFilter: { id: [id] } } };
  const itemsRef = useRef([]);
  const lookupItem = (id) => itemsRef.current.find((it) => it.id === id);

  const { loading, error, data, client } = useSafeQuery(QUERY_BY_MODULE, { id });
  const [updateMutation] = useMutation(metadata.updateMutation);

  const { columnDefs } = useColumnDefs(client, metadata, variables, [client], (defs) => {
    defs.unshift(getExpandButtonColDef(lookupItem, onView));
  });

  if (loading) {
    return <ActivityIndicator size='large' />;
  }

  if (error) {
    return <ErrorMessage message={error.message} />;
  }

  const items = (data && data.queryFeature) || [];
  itemsRef.current = items.map((item) => metadata.getFlattenedValues(item, EDIT_MODE));

  return (
    <View className='ag-theme-balham' style={{ height: '60vh', width: '100%' }}>
      <ModelGrid
        columnDefs={columnDefs}
        rowData={itemsRef.current}
        metadata={metadata}
        mutate={updateMutation}
      />
    </View>
  );
};

export default FeaturesGrid;
