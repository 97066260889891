import getSettings from '../shared/settings';

const { apiUrl } = getSettings();

export const reIndexRequest = () => {
  return {
    url: `${apiUrl}rebuild-search-index`,
    method: 'POST',
  };
};

export const saveIndexRequest = () => {
  return {
    url: `${apiUrl}save-search-index`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };
};

export const deleteIndexRequest = () => {
  return {
    url: `${apiUrl}delete-search-index`,
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };
};