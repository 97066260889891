import React from 'react'
import { useQuery } from '@apollo/client'
import { View, Text } from 'react-native'
import { FieldView } from '../../styles/components';
import { ActivityIndicator } from '../../styles/components';

const ItemViewComponent = ({ id, metadata }) => {
  const { loading, error, data } = useQuery(metadata.getItemQuery, { variables: { id } });

  if (loading) {
    return (
      <ActivityIndicator size="large" />
    );
  }

  if (error) {
    console.log(error);
    return (
      <View>
        <Text>{error.message}</Text>
      </View>
    )
  }

  const item = data && data[metadata.queryAll] && data[metadata.queryAll][0] || {};

  return (
    <View style={ { flex: 1, margin: 15 }}>
      <FieldView label="Module" value={item.module.name} />
      <FieldView label="Code" value={item.code} />
      <FieldView label="Name" value={item.name} />
      <FieldView label="Icon" value={item.icon} />
      <FieldView label="Strap Line" value={item.strap_line} />
      <FieldView label="Description" value={item.description} />
      <FieldView label="Remarks" value={item.remarks} />
    </View>
  );
};

export default ItemViewComponent;