import { useQuery } from '@apollo/client'
import React from 'react'
import { FlatList, Platform, Text, View } from 'react-native'
import { SafeAreaView } from 'react-native-safe-area-context'
import { withTheme } from 'styled-components/native'
import styles from '../assets/css/styles'
import { ModuleItem } from '../components/ModuleItem'
import ModelMetaDataMap from '../models'
import ErrorScreen from '../screens/ErrorScreen'
import Search from '../search/Search'
import { ActivityIndicator } from '../styles/components'

const TopNavigation = () => (
  <View>
    <Text>TopNavigation comes here</Text>
  </View>
)

const HomeScreen = (props) => {
  const module = "Module";
  const metadata = ModelMetaDataMap[module];

  if (metadata == undefined) {
    return <ErrorScreen message={`Metadata not configured for ${module}`} />;
  }

  const { loading, error, data } = useQuery(metadata.fetchQuery);

  // const [deleteEntity, { result }] = useMutation(metadata.deleteMutation, {
  //   refetchQueries: metadata.refetchQueries,
  //   onCompleted: (data) => {
  //     console.log(`${metadata.name} deleted successfully!`);
  //   }
  // });

  if (loading) {
    return (
      <View style={[styles.container, styles.horizontal]}>
        <ActivityIndicator size="large" />
      </View>
    );
  }

  if (error) {
    console.log(error);
    return <ErrorScreen message={error.message} />;
  }

  const items = data && data[metadata.queryAll] || [];
  const columns = Platform.OS == "web" ? 4 : 2;

  const onPress = item => {
    props.navigation.navigate('ModuleScreen', { moduleId: item.id, title: '' });
  }

  const itemRenderer = ({ item }) => (
    <ModuleItem item={item} onPress={onPress} />
  )

  return (
    <SafeAreaView>
      <Search navigation={props.navigation}/>
      <FlatList
        data={items}
        renderItem={itemRenderer}
        keyExtractor={item => item.id}
        numColumns={columns}

      />
    </SafeAreaView>
  )
}

export default withTheme(HomeScreen)


