import React from 'react';
import { TouchableOpacity } from 'react-native';
import { BodyText, Chevron, ListItemPanel, SectionWrapper, SubText } from '../../styles/components';
import ItemDetailComponent from './ItemDetailComponent';
import ItemViewComponent from './ItemViewComponent';
import { ListItem } from 'react-native-elements';

export const createItemView = (item, metadata, navigation) => {
  return props => {
    return (
      <ItemViewComponent id={item.id} metadata={metadata} navigation={navigation}/>
    )
  }
}

export const createItemDetailView = (item, metadata) => {
  return props => {
    return (
      <ItemDetailComponent id={item.objectID} metadata={metadata}/>
    )
  }
}

export const createListItemRenderer = (onPress) => {
  return ({ item }) => {
    return (
      <ListItem key={item.id} onPress={() => onPress(item)} bottomDivider>
        <ListItem.Content>
          <ListItem.Subtitle>{item.code}</ListItem.Subtitle>
          <ListItem.Title style={{ fontSize: 18 }}>{item.name}</ListItem.Title>
        </ListItem.Content>
        <ListItem.Chevron />
      </ListItem>
    );
  };
};

