import { FontAwesome5 } from "@expo/vector-icons";
import React, { useState } from "react";
import { StyleSheet, TouchableOpacity, View } from "react-native";
import { FabTheme } from '../styles/theme';

const HeaderMenu = ({ children, navigation }) => {
  const [showMenu, setShowMenu] = useState(false);

  React.useLayoutEffect(() => {
    navigation.setOptions({
      headerRight: () => (
        <TouchableOpacity
          onPress={() => setShowMenu((showMenu) => !showMenu)}
          style={{ width: 30 }}
        >
          <FontAwesome5 name="ellipsis-v" size={24} color={FabTheme.colors.primary} />
        </TouchableOpacity>
      ),
    });
  }, [navigation]);

  return <>{showMenu ? <View style={styles.menu}>{children}</View> : null}</>;
};

const styles = StyleSheet.create({
  menu: {
    alignItems: "flex-start",
    flex: 1,
    position: "absolute",
    top: 0,
    right: 5,
    padding: 10,
    width: 200,
    backgroundColor: "#fff",
    shadowColor: "#000",
    shadowOffset: {
      width: 0,
      height: 2,
    },
    shadowOpacity: 0.25,
    shadowRadius: 3.84,
    elevation: 5,
  },
});

export default HeaderMenu;
