import { useQuery } from '@apollo/client';
import { FontAwesome5 } from '@expo/vector-icons';
import React, { useContext, useEffect, useState } from 'react';
import { Dimensions, Modal, Platform, Text, TouchableOpacity, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import AddModelComponent from '../components/AddModelComponent';
import FeatureViewComponent from '../components/FeatureViewComponent';
import HeaderMenu from "../components/HeaderMenu";
import ModuleAreas from '../components/ModuleAreas';
import { QUERY_BY_MODULE } from '../models/feature/graphql';
import { QUERY_ITEM } from '../models/module/graphql';
import { StateContext } from '../shared/state';
import {
  ActivityIndicator,
  BodyText,
  ErrorMessage,
  Heading1,
  HeadingWrapper,
  ModalContainerView,
  ModalInnerView,
  SectionWrapper,
  SmallIcon,
  SubText
} from '../styles/components';

const window = Dimensions.get('window');

const AddFeatureModal = (props) => {
  const { id, modalVisible, setModalVisible, onSave } = props;
  const formOptions = {
    quickAdd: true,
    referenceFilters: { area: { moduleFilter: { id: [id] } } },
  };
  const width = Platform.OS == 'web' ? '500px' : '100%';
  return (
    <Modal
      animationType='slide'
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => setModalVisible(!modalVisible)}
    >
      <ModalContainerView>
        <ModalInnerView style={{ width, maxHeight: 650 }}>
          <HeadingWrapper
            style={{ margin: 15, justifySelf: 'stretch', justifyContent: 'space-between' }}
          >
            <Heading1>Add Feature</Heading1>
            <FontAwesome5 name='times' size={20} onPress={() => setModalVisible(!modalVisible)} />
          </HeadingWrapper>
          <AddModelComponent
            model='Feature'
            onSave={onSave}
            formOptions={formOptions}
            localRefetchQueries={[QUERY_BY_MODULE]}
          />
        </ModalInnerView>
      </ModalContainerView>
    </Modal>
  );
};

const ViewFeatureModal = (props) => {
  const { feature, width, modalVisible, setModalVisible } = props;
  return (
    <Modal
      animationType='slide'
      transparent={true}
      visible={modalVisible}
      onRequestClose={() => setModalVisible(!modalVisible)}
    >
      <ModalContainerView>
        <ModalInnerView style={{ width, minHeight: 400, maxHeight: 650 }}>
          <FontAwesome5
            name='times'
            size={20}
            style={{ alignSelf: 'flex-end' }}
            onPress={() => setModalVisible(!modalVisible)}
          />
          {feature ? <FeatureViewComponent feature={feature} /> : null}
        </ModalInnerView>
      </ModalContainerView>
    </Modal>
  );
};

const ModuleScreen = (props) => {
  const [dimensions, setDimensions] = useState({ window });
  const [modalVisible, setModalVisible] = useState(false);
  const [viewFeatureModalVisible, setViewFeatureModalVisible] = useState(false);
  const [feature, setFeature] = useState(false);
  const { user, setUser } = useContext(StateContext);
  const { navigation } = props;
  const { moduleId } = props.route.params;

  const isLargeScreen = dimensions.window.width >= 768;
  const width = isLargeScreen && Platform.OS == 'web' ? 600 : '90%';

  const onChange = ({ window }) => {
    setDimensions({ window });
  };

  useEffect(() => {
    Dimensions.addEventListener('change', onChange);
    return () => {
      Dimensions.removeEventListener('change', onChange);
    };
  });

  if (moduleId == undefined) {
    return <ErrorMessage message={`ModuleId not supplied!`} />;
  }

  const { loading, error, data } = useQuery(QUERY_ITEM, { variables: { id: moduleId } });

  if (loading) {
    return <ActivityIndicator size='large' />;
  }

  if (error) {
    return <ErrorMessage message={error.message} />;
  }

  const module = (data && data.queryModule[0]) || {};

  const onSave = () => {
    setModalVisible(false);
  };

  const AddFeatureButton = () => (
    <TouchableOpacity
      onPress={() => setModalVisible(true)}
      style={{ flexDirection: "row" }}
    >
      <FontAwesome5 name="plus-square" size={17} />
      <Text style={{ marginLeft: 7 }}>Add Feature</Text>
    </TouchableOpacity>
  );
  const onFeatureView = (item) => {
    setFeature(item);
    setViewFeatureModalVisible(true);
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <AddFeatureModal
        id={module.id}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        onSave={onSave}
      />
      <ViewFeatureModal
        feature={feature}
        width={width}
        modalVisible={viewFeatureModalVisible}
        setModalVisible={setViewFeatureModalVisible}
      />
      <HeadingWrapper style={{ marginTop: -5 }}>
        <SmallIcon name={module.icon} />
        <SectionWrapper>
          <SubText>{module.code}</SubText>
          <Heading1>{module.name}</Heading1>
        </SectionWrapper>
      </HeadingWrapper>
      <View>
        <SectionWrapper>
          {module.strapLine && <BodyText>{module.strapline}</BodyText>}
          <BodyText>{module.description}</BodyText>
        </SectionWrapper>
      </View>
      <ModuleAreas module={module} navigation={navigation} />
      <HeaderMenu navigation={navigation}>
        <AddFeatureButton />
      </HeaderMenu>
    </SafeAreaView>
  );
};

export default ModuleScreen;
