import { useQuery, gql } from '@apollo/client';

const AssetsFragment = gql`
  fragment AssetsFragment on Asset {
    id
    module {
      id
      name
    }
    title
    description
    created
    modified
    remarks
    asset_type {
      id
      name
      icon
      scheme
      downloadable
    }
    intent {
      id
      name
    }
    persona {
      id
      name
    }
    url
    image
  }
`;

export const QUERY_ALL = gql`
  query AllAssets {
    queryAsset(order: {asc: title}) {
      ...AssetsFragment
    }
  }
  ${AssetsFragment}
`;

export const ADD_MUTATION = gql`
  mutation addAsset($record: AddAssetInput!) {
    addAsset(input: [$record]) {
      asset {
        ...AssetsFragment
      }
    }
  }
  ${AssetsFragment}
`;

export const UPDATE_MUTATION = gql`
  mutation UpdateAsset($patch: UpdateAssetInput!) {
    updateAsset(input: $patch) {
      asset {
        ...AssetsFragment
      }
    }
  }
  ${AssetsFragment}
`;

export const DELETE_MUTATION = gql`
mutation deleteAsset($filter: AssetFilter!) {
  deleteAsset(filter: $filter) {
    msg
  }
}
`;

export const QUERY_ITEM = gql`
  query ReadAsset($id: ID!) {
    queryAsset(filter: {id: [$id]}) {
      ...AssetsFragment
    }
  }
  ${AssetsFragment}
`;



export const QUERY_ASSET_TYPES = gql`
  query AllAssetTypes {
    queryAssetType(order: {asc: name}) {
      id
      name
    }
  }
`;

export const QUERY_INTENTS = gql`
  query AllIntents {
    queryIntent(order: {asc: name}) {
      id
      name
    }
  }
`;

export const QUERY_PERSONAS = gql`
  query AllPersonas {
    queryPersona(order: {asc: name}) {
      id
      name
    }
  }
`;

export const QUERY_MODULES = gql`
  query AllModules {
    queryModule(order: {asc: name}) {
      id
      name
    }
  }
`;

export const updateCache = (cache, { data }) => {
  console.log('updating the cache for new asset');
  //debugger;
  //const newAsset = data.addAsset.returning[0];
  //const existingAssets = cache.readQuery({ query: QUERY_ALL });
  //const assets = [...existingAssets, addAsset];
  //console.log(assets);
  //cache.writeQuery({ query: QUERY_ALL, data: { assets } });
}


