export const FabTheme = {
  colors: {
    primary: '#3626A7',
    primary2: '#3863CA',
    primary3: '#3982DC',
    primary4: '#39A0ED',
    primaryBackground: '#dbedf9',
    secondary: '#38C9DD',
    secondary2: '#38D3D9',
    secondary3: '#37DDD5',
    secondary4: '#37E7D1',
    secondaryBackground: '#defcf8',
    text: '#333',
    subText: '#666',
    listBackground: '#fff',
    lightGray: '#ccc',
    highlight: '#ffff84',
  },

  fontSizes: {
    heading1: 24,
    heading2: 22,
    heading3: 20,
    heading4: 18,
  },
}

