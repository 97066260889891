import { connect, getIn } from 'formik';
import React from 'react';
import { Asterisk } from '../styles/components';
import {
  ErrorText,
  FieldWrapper,
  LabelText,
  StyledInput
} from '../styles/controls';

const TextInput = (props) => {
  const { label, name, metadata, formik, ...rest } = props;
  const { values, errors, setFieldValue } = formik;

  const value = getIn(values, name);
  const error = getIn(errors, name);
  const touched = getIn(touched, name);
  const isRequired = metadata.isFieldRequired(name);

  return (
    <FieldWrapper>
      <LabelText>
        {label} {isRequired ? <Asterisk /> : null}
      </LabelText>
      <StyledInput
        value={value ? value + '' : ''}
        onChangeText={(text) => setFieldValue(name, text)}
        {...rest}
      />
      <ErrorText>{error}</ErrorText>
    </FieldWrapper>
  );
};

export default connect(TextInput);
