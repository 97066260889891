import * as Colors from './colors';
import * as Spacing  from './spacing';

export const extraLargeFontSize = 36;
export const largeFontSize = 24;
export const buttonFontSize = 18;
export const inputFontSize = 18;
export const sideMenuFontSize = 18;
export const baseFontSize = 16;
export const smallFontSize = 14;
export const smallerFontSize = 13;
export const smallestFontSize = 10;
export const largeHeaderFontSize = 20;
export const headerFontSize = 18;
export const fontFamilyNormal = 'OpenSans-Regular';
export const fontFamilySemiBold = 'OpenSans-SemiBold';
export const fontfamilyBold = 'OpenSans-Bold';

const base = {
  alignItems: 'center',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
};

export const buttonLink = {
  color: Colors.white,
  fontFamily: fontFamilyNormal,
};

export const bodyText = {
  color: Colors.baseText,
  fontSize: smallFontSize,
  fontFamily: fontFamilyNormal,
};

export const sideMenuText = {
  color: Colors.baseText,
  fontSize: sideMenuFontSize,
  fontFamily: fontFamilyNormal,
};

export const smallNormalText = {
  color: Colors.baseText,
  fontSize: smallFontSize,
  fontFamily: fontFamilyNormal,
};

export const smallerNormalText = {
  color: Colors.baseText,
  fontSize: smallerFontSize,
  fontFamily: fontFamilyNormal,
};

export const cardHeadings = {
  color: Colors.baseText,
  fontSize: headerFontSize,
  fontFamily: fontfamilyBold,
};

export const cardCenterHeadings = {
  ...Spacing.centerAlign,
  color: Colors.baseText,
  fontSize: smallFontSize,
  fontFamily: fontfamilyBold,
};

export const cardCenterSubText = {
  ...Spacing.centerAlign,
  color: Colors.baseText,
  fontSize: smallFontSize,
  fontFamily: fontFamilyNormal,
};

export const netPayText = {
  color: Colors.netPayTextColor,
  fontSize: smallFontSize,
  fontFamily: fontFamilyNormal,
};

export const deductionsText = {
  color: Colors.deductionsTextColor,
  fontSize: smallFontSize,
  fontFamily: fontFamilyNormal,
};

export const taxPaidText = {
  color: Colors.taxPaid,
  fontSize: smallFontSize,
  fontFamily: fontFamilyNormal,
};

export const balanceTaxesText = {
  color: Colors.balanceTaxes,
  fontSize: smallFontSize,
  fontFamily: fontFamilyNormal,
};

export const mainHeaderText = {
  color: Colors.baseText,
  fontSize: extraLargeFontSize,
  fontFamily: fontfamilyBold,
};
export const normalText = {
  color: Colors.baseText,
  fontSize: baseFontSize,
  fontFamily: fontFamilyNormal,
};

export const largeText = {
  color: Colors.baseText,
  fontSize: largeFontSize,
  fontFamily: fontFamilySemiBold,
};

export const buttonText = {
  ...base,
  color: Colors.whiteText,
  fontSize: buttonFontSize,
  fontFamily: fontFamilyNormal,
};

export const inputText = {
  ...base,
  color: Colors.baseText,
  fontSize: inputFontSize,
  fontFamily: fontFamilyNormal,
};

export const mainPageHeader = {
  ...base,
  color: Colors.whiteText,
  fontSize: largeHeaderFontSize,
};

export const secondaryFooterText = {
  color: Colors.secondaryText,
  fontSize: smallerFontSize,
  fontFamily: fontFamilyNormal,
};
