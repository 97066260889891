import { FontAwesome5 } from '@expo/vector-icons';
import 'ag-grid-enterprise';
import { AgGridReact } from 'ag-grid-react';
import React from 'react';
import { TouchableOpacity, View } from 'react-native';
import { EDIT_MODE } from '../models';

const EXPAND_ICON = 'expand';

const IconButtonCell = ({ icon, onPress }) => {
  return (
    <TouchableOpacity onPress={onPress}>
      <View style={{ justifyContent: 'center' }}>
        <FontAwesome5 name={icon} size={16} color='black' style={{ marginHorizontal: 3 }} />
      </View>
    </TouchableOpacity>
  );
};

export const getButtonColDef = (icon, lookupItem, onPress) => {
  return {
    headerName: '',
    field: 'expand',
    width: 40,
    cellRendererFramework: function (params) {
      const onButtonPress = () => {
        const item = lookupItem(params.node.id);
        onPress(item, params.rowIndex);
      };
      return (
        <View style={{ flexDirection: 'row' }}>
          <IconButtonCell icon={icon} onPress={onButtonPress} />
        </View>
      );
    },
  };
};

export const getExpandButtonColDef = (lookupItem, onPress) => {
  return getButtonColDef(EXPAND_ICON, lookupItem, onPress);
}

const ModelGrid = ({ gridOptions, columnDefs, rowData, metadata, mutate }) => {

  const localGridOptions = {
    ...gridOptions,
    getRowNodeId: data => data.id,
    groupUseEntireRow: true,
    rowGroupPanelShow: 'always',
    defaultGroupSortComparator: function (nodeA, nodeB) {
      if (nodeA.key < nodeB.key) {
        return -1;
      } else if (nodeA.key > nodeB.key) {
        return 1;
      } else {
        return 0;
      }
    },
    statusBar: {
      statusPanels: [
        { statusPanel: 'agTotalAndFilteredRowCountComponent', align: 'left' }
      ]
    }
  };

  const onCellValueChanged = (event) => {
    const rowId = event.node.id;
    const values = {};
    values[event.colDef.field] = event.newValue;
    const record = metadata.getShapedValues(values, EDIT_MODE);
    const patch = { filter: { id: [rowId] }, set: record }

    setTimeout(() => {
      mutate({ variables: { patch } })
        .catch(e => {
          console.log(`${metadata.name}: update mutation failed`, e);
        });
    }, 10);
  }

  return (
      <AgGridReact
        columnDefs={columnDefs}
        rowData={rowData}
        gridOptions={localGridOptions}
        onCellValueChanged={onCellValueChanged}
      />
  );
}

export default ModelGrid;
