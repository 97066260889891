import { useQuery } from '@apollo/client';
import React from 'react';
import { FlatList, StyleSheet, Text, View } from 'react-native';
import { TouchableOpacity } from 'react-native-gesture-handler';
import { SafeAreaView } from 'react-native-safe-area-context';
import { QUERY_ITEM } from '../models/area/graphql';
import {
  ActivityIndicator,
  BodyText,
  ErrorMessage,
  Heading1,
  Heading2,
  HeadingWrapper,
  ListItemPanel,
  ListWrapper,
  SectionWrapper,
  SubText
} from '../styles/components';

const TopNavigation = () => (
  <View>
    <Text>TopNavigation comes here</Text>
  </View>
);

const AreaScreen = (props) => {
  const { navigation } = props;
  const { areaId } = props.route.params;

  const renderItem = ({ item, index }) => {
    console.log(item);
    return (
      <TouchableOpacity
        style={{ flex: 1 }}
        onPress={() => navigation.navigate('FeatureScreen', { featureId: item.id })}
      >
        <ListWrapper>
          <ListItemPanel>
            <SectionWrapper>
              <BodyText style={{ fontSize: 14 }}>{item.name}</BodyText>
            </SectionWrapper>
          </ListItemPanel>
        </ListWrapper>
      </TouchableOpacity>
    );
  };

  if (areaId == undefined) {
    return <ErrorMessage message={`Area not supplied!`} />;
  }

  const { loading, error, data } = useQuery(QUERY_ITEM, { variables: { id: areaId } });

  if (loading) {
    return <ActivityIndicator size='large' />;
  }

  if (error) {
    return <ErrorMessage message={error.message} />;
  }

  const area = (data && data.queryArea[0]) || {};
  console.log('got area ', area);

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <HeadingWrapper style={{}}>
        <SectionWrapper>
          <SubText>
            {area.module.code}/{area.code}
          </SubText>
          <Heading1>{area.name}</Heading1>
        </SectionWrapper>
      </HeadingWrapper>
      {area.strapline && <BodyText>{area.strapline}</BodyText>}
      <BodyText>{area.description}</BodyText>
      <Heading2>Features</Heading2>
      <FlatList
        style={styles.container}
        data={area.features}
        renderItem={renderItem}
        keyExtractor={(item) => item.id}
        enableEmptySections={true}
      />
    </SafeAreaView>
  );
};

const styles = StyleSheet.create({
  container: { margin: 0, flex: 1, marginTop: 10 },

  item: {
    padding: 15,
    marginVertical: 1,
    borderBottomWidth: 1,
    borderColor: '#ccc',
    backgroundColor: '#fff',
  },

  title: {
    fontSize: 18,
  },
});

export default AreaScreen;
