import { useQuery, gql } from '@apollo/client';

const AreasFragment = gql`
  fragment AreasFragment on Area {
    id
    module {
      id
      code
      name
    }
    code
    name
    strap_line
    description
    created
    is_published
    icon
    remarks
    features {
      id
      code
      name
    }
  }
`;

export const QUERY_ALL = gql`
  query AllAreas {
    queryArea(order: {asc: name}) {
      ...AreasFragment
    }
  }
  ${AreasFragment}
`;

export const ADD_MUTATION = gql`
  mutation addArea($record: AddAreaInput!) {
    addArea(input: [$record]) {
      area {
        ...AreasFragment
      }
    }
  }
  ${AreasFragment}
`;

export const UPDATE_MUTATION = gql`
  mutation UpdateArea($patch: UpdateAreaInput!) {
    updateArea(input: $patch) {
      area {
        ...AreasFragment
      }
    }
  }
  ${AreasFragment}
`;

export const DELETE_MUTATION = gql`
mutation deleteArea($filter: AreaFilter!) {
  deleteArea(filter: $filter) {
    msg
  }
}
`;

export const QUERY_MODULES = gql`
  query AllModules {
    queryModule(order: {asc: name}) {
      id
      name
    }
  }
`;

export const QUERY_ITEM = gql`
  query ReadArea($id: ID!) {
    queryArea(filter: {id: [$id]}) {
      ...AreasFragment
    }
  }
  ${AreasFragment}
`;

export const updateCache = (cache, { data: { addArea } }) => {
  cache.modify({
    fields: {
      areas(existingItems = []) {
        const newItemRef = cache.writeFragment({
          data: addArea,
          fragment: AreasFragment,
        });
        return [...existingItems, newItemRef];
      }
    }
  });
}


