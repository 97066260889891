import React, {Component} from 'react';
import {Text, View, Image} from 'react-native';
import styles from '../assets/css/styles';
import noDataImage from '../assets/images/noDataImage.png';
import headerImage from '../assets/images/header.png';
import {Typography} from '../assets/css';

export class ErrorScreen extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: props.message
    }
  }

  async componentDidMount() {
    if (this.props.route) {
      const { message } = this.props.route.params;
      this.setState({ message: message });
    }
  }

  render() {
    return (
      <View>
        <Image style={styles.mainPageHeaderImage} source={headerImage} />
        <View style={styles.homepageCompanyName}>
          <Text style={styles.fontWhite14}>greytHR Employee Portal</Text>
        </View>
        {/* Display error heading */}
        <View style={styles.helloUser}>
          <View style={styles.helloCard}>
            <Text style={styles.fontBlack22}> Error </Text>
          </View>
        </View>
        {/* Display error message text */}
        <View style={styles.centerAlignPadding}>
          <Image source={noDataImage} />
          <Text style={Typography.cardCenterSubText}>
            {this.state.message}
          </Text>
          <Text style={Typography.cardCenterSubText}>
            Please try after sometime.
          </Text>
        </View>
      </View>
    );
  }
}

export default ErrorScreen;
