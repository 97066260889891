import { FontAwesome5 } from '@expo/vector-icons';
import React, { useContext } from 'react';
import { ActivityIndicator as RNActivityIndicator, Platform, View } from 'react-native';
import styled, { ThemeContext } from 'styled-components/native';

export const ModalContainerView = styled.View`
  flex: 1
  justify-content: center;
  align-items: center;
`;

export const ModalInnerView = styled.View`
  padding: 15px;
  background-color: ${(props) => props.theme.colors.primaryBackground};
  border-radius: 10px;
  shadow-color: #000;
  shadow-offset: {
    width: 0;
    height: 2px;
  }
  shadow-opacity: 0.25;
  shadow-radius: 3.84px;
  elevation: 5;
`;

export const FontView = styled.View`
  display: flex;
  flex-direction: row;
  border: 1px solid ${(props) => props.theme.colors.primary};
  border-radius: 50px;
  padding: 8px;
  margin: 15px;
  background-color: ${(props) => props.theme.colors.primary};
  align-items: center;
  justify-content: center;
`;

export const SmallIcon = ({ name }) => {
  const icon = name || 'asterisk';
  return (
    <FontView style={{ width: 36, marginLeft: 5, marginRight: 5 }}>
      <Font name={icon} size={16} />
    </FontView>
  );
};

export const MediumIcon = ({ name }) => {
  const icon = name || 'asterisk';
  return (
    <FontView style={{ width: 44, marginLeft: 5, marginRight: 5 }}>
      <Font name={icon} size={24} />
    </FontView>
  );
};

export const Icon = ({ name }) => {
  const icon = name || 'asterisk';
  return (
    <FontView style={{ width: 56 }}>
      <Font name={icon} size={32} />
    </FontView>
  );
};
export const Font = (props) => {
  const theme = useContext(ThemeContext);

  return <FontAwesome5 color={theme?.colors.secondary} {...props} />;
};

export const FontButtonWrapper = (props) => {
  const { children, ...rest } = props;

  const setNativeProps = (nativeProps) => {
    _root.setNativeProps(nativeProps);
  };

  return (
    <View style={{ marginTop: 50 }} ref={(component) => (_root = component)} {...rest}>
      {props.children}
    </View>
  );
};

export const Heading1 = styled.Text`
  font-size: ${(props) => props.theme.fontSizes.heading1}px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.primary};
  margin: 0;
`;

export const Heading2 = styled.Text`
  font-size: ${(props) => props.theme.fontSizes.heading2}px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.primary};
  margin: 0;
`;

export const BodyText = styled.Text`
  font-size: 18px;
  margin: 10px 0;
`;

export const SubText = styled.Text`
  font-size: 14px;
  color: ${(props) => props.theme.colors.subText};
`;

const FieldInnerView = styled.View`
  flex: 1;
  flex-direction: column;
  align-self: stretch;
  border-color: red;
  border-bottom-color: ${(props) => props.theme.colors.lightGray};
  border-bottom-width: 1px;
  padding-vertical: 5px;
  margin-bottom: 30px;
`;

const LabelText = styled.Text`
  font-size: 12px;
  color: ${(props) => props.theme.colors.subText};
  margin-bottom: 5px;
  text-transform: uppercase;
`;

const ValueText = styled.Text`
  font-size: 18px;
`;

export const ActivityIndicator = (props) => {
  const theme = useContext(ThemeContext);

  return <RNActivityIndicator color={theme?.colors.primary} {...props} />;
};

export const WrapperView = styled.ScrollView`
`;

export const ModuleWrapper = styled.View`
  align-self: stretch;
  align-items: center;
  flex: ${(prop) => (Platform.OS == 'web' ? 1 : 0.5)};
`;

export const HeadingWrapper = styled.View`
  flex-direction: row;
  align-items: center;
`;

export const SectionWrapper = styled.View`
  flex-direction: column;
  flex-grow: 1;
  padding-left: 5px;
`;

export const ModuleView = styled.View`
  flex-direction: column;
  margin: 15px;
  padding: 5px;
  width: 180px;
  min-height: 160px;
  align-items: center;
  border: 1px solid ${(props) => props.theme?.colors.primary};
  border-radius: 8px;
  background-color: ${(props) => props.theme?.colors.secondaryBackground};
`;

export const ListWrapper = styled.View`
  margin-top: 3px;
  min-height: 80px;
  border-bottom-width: 1px;
  border-color: ${(props) => props.theme?.colors.primary};
  align-items: center;
  background-color: ${(props) => props.theme.colors.listBackground};
`;

export const ListItemPanel = styled.View`
  flex: 1;
  flex-direction: row;
  justify-content: center;
  alignself: stretch;
  align-items: center;
  min-height: 100px;
  background-color: white;
  padding: 5px;
  margin-bottom: 3px;
`;

const ChevronView = styled.View`
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  margin-right: 5px;
`;

const ErrorView = styled.View`
  justify-content: center;
  align-items: center;
  margin: 15px;
  padding: 15px;
  border: 1px solid #ad0606;
`;

export const Chevron = (props) => (
  <ChevronView>
    <Font name='chevron-right' size={16} />
  </ChevronView>
);

export const FieldView = ({ label, value }) => (
  <FieldInnerView>
    <LabelText>{label}</LabelText>
    <ValueText>{value}</ValueText>
  </FieldInnerView>
);

export const ErrorMessage = ({ message, children }) => (
  <ErrorView>
    <BodyText>{message || children}</BodyText>
  </ErrorView>
);

export const Asterisk = () => (
  <LabelText style={{color: 'red', fontSize: '12px'}}>*</LabelText>
)
