import * as AuthSession from 'expo-auth-session';
import { useAuthRequest } from 'expo-auth-session';
import * as Crypto from 'expo-crypto';
import { LinearGradient } from 'expo-linear-gradient';
import * as Random from 'expo-random';
import * as WebBrowser from 'expo-web-browser';
import React, { useContext, useState } from 'react';
import { Button, Platform, StyleSheet, View } from 'react-native';
import { createUserInfo, saveIdToken } from '../shared/auth';
import getSettings from '../shared/settings';
import { StateContext } from '../shared/state';

const POST_LOGIN_SCREEN = 'Home';

const {
  auth0: { auth0ClientId, auth0Domain, scopes, apiIdentifier },
} = getSettings();

const useProxy = Platform.select({ web: false, default: true });

const getAuthRequestInputs = async () => {
  const redirectUri = AuthSession.makeRedirectUri({
    native: 'fab://redirect',
    useProxy,
  });

  console.log('redirect url', redirectUri);
  console.log('client id', auth0ClientId);
  const [verifier, codeChallenge] = await createVerifierChallenge();

  // Structure the auth parameters and URL
  const params = {
    clientId: auth0ClientId,
    responseType: 'token id_token',
    scopes: scopes,
    extraParams: {
      nonce: verifier,
      audience: apiIdentifier,
    },
    redirectUri,
    codeChallenge,
  };
  return params;
};

function base64URLEncode(str) {
  return str
    .toString('base64')
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
}

export const createVerifierChallenge = () => {
  return new Promise(async (resolve, reject) => {
    const randomBytes = await Random.getRandomBytesAsync(32);
    const verifier = base64URLEncode(Buffer.from(randomBytes));

    const challengeBase64 = await Crypto.digestStringAsync(
      Crypto.CryptoDigestAlgorithm.SHA256,
      verifier,
      { encoding: Crypto.CryptoEncoding.BASE64 }
    );
    const challenge = challengeBase64
      .replace(/\+/g, '-')
      .replace(/\//g, '_')
      .replace(/=/g, '');

    resolve([verifier, challenge]);
  });
};

const useAuthParams = (loadedParams) => {
  const emptyParams = {
    clientId: auth0ClientId,
    scopes: scopes,
    redirectUri: 'fab:/ouathredirect',
  };
  const [params, setParams] = React.useState(emptyParams);
  React.useEffect(() => {
    async function fetchResult() {
      const result2 = await getAuthRequestInputs();
      console.log('got the auth request result!');
      setParams(result2);
    }
    if (loadedParams == null) {
      console.log('fetching auth req params');
      fetchResult();
    }
  });

  return params;
};

WebBrowser.maybeCompleteAuthSession();

//
// I have used the loadedParams trick to prevent endless looping og useAuthParams() hook
// I guess it's due to the fact that we are generating a random value in the params object
// and it leads to dirtying of the old state leading to re-rendering.
//

const LoginScreen = (props, state) => {
  const { user, setUser } = useContext(StateContext);
  const [loadedParams, setLoadedParams] = useState(null);
  const params = useAuthParams(loadedParams);
  const discovery = AuthSession.useAutoDiscovery(`https://${auth0Domain}`);
  const [request, response, promptAsync] = useAuthRequest(params, discovery);

  console.log('inside login page');

  React.useEffect(() => {
    if (params != null) {
      setLoadedParams(params);
      // console.log(params);
    }
  }, [params]);

  React.useEffect(() => {
    if (response?.type === 'success') {
      const { access_token, id_token } = response.params;
      const userInfo = createUserInfo(id_token);
      const user = { ...userInfo, access_token, id_token };
      console.log(user);
      setUser(user);
      if (__DEV__ || Platform.OS !== 'web') {
        saveIdToken(access_token, id_token, user.exp);
      } else {
        // don't store secrets to AsyncStorage in case of web app
      }
      // props.navigation.navigate(POST_LOGIN_SCREEN);
    }
  }, [response]);

  return (
    <View style={styles.container}>
      <LinearGradient colors={['#b1f7f6', '#3b5998', '#288fb3']}>
        <Button
          disabled={!request}
          title='Login'
          onPress={() => {
            promptAsync({ useProxy });
          }}
        />
      </LinearGradient>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    flex: 5,
    alignItems: 'center',
    justifyContent: 'center',
  },
});

export default LoginScreen;
