import { useQuery } from '@apollo/client';
import { connect, getIn } from 'formik';
import React from 'react';
import { Picker, Platform } from 'react-native';
import { ActivityIndicator, Asterisk, ErrorMessage } from '../styles/components';
import { ErrorText, FieldWrapper, LabelText } from '../styles/controls';

const ModelPicker = (props) => {
  const { label, name, metadata, style, formik, formOptions, ...rest } = props;
  const { values, errors, setFieldValue } = formik;

  const value = getIn(values, name) || '';
  const errorMsg = getIn(errors, name);
  const touched = getIn(touched, name);
  const field = metadata.find(name);
  const referenceFilter =
    (formOptions && formOptions.referenceFilters && formOptions.referenceFilters[name]) || {};
  const isRequired = metadata.isFieldRequired(name);

  if (field == undefined) {
    return <ErrorMessage>Field not found: {name}</ErrorMessage>
  }

  if (field.relation == undefined) {
    return <ErrorMessage message={`Field does not have relation data: ${name}`} />;
  }

  const { foreignKey, foreignLabel, query, queryName } = field.relation;
  const { loading, error, data } = useQuery(query, { variables: referenceFilter });

  if (loading) {
    return <ActivityIndicator size='small' />;
  }

  if (error) {
    return <ErrorMessage message={error.message} />;
  }

  const items = (data && data[queryName]) || [];

  return (
    <FieldWrapper>
      <LabelText>{label} {isRequired ? <Asterisk /> : null}</LabelText>
      <Picker
        selectedValue={value}
        onValueChange={(value, itemIndex) => setFieldValue(name, value)}
        style={[style, field.getRelationStyle(Platform.OS)]}
      >
        <Picker.Item label={'(pick a value)'} value={''} />
        {items.map((item) => (
          <Picker.Item key={item[foreignKey]} label={item[foreignLabel]} value={item[foreignKey]} />
        ))}
      </Picker>
      <ErrorText>{errorMsg}</ErrorText>
    </FieldWrapper>
  );
};

export default connect(ModelPicker);
