
import styled from 'styled-components/native'
import { Platform } from 'react-native'

export const StyledSafeAreaView = styled.SafeAreaView`
  margin-horizontal: ${Platform.OS === 'web'? 10 : 0}%;
`

export const WebContainer = styled.View`
   flex-direction: row;
   justify-content: center;
   align-items: ${Platform.OS === 'web' ? 'center' : 'stretch'};
   margin: 10px 0;
`

export const WebButtonSeparator = styled.View`
   width: 20px;
`
