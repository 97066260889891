import useAxios from 'axios-hooks';
import { useContext } from 'react';
import { StateContext } from '../shared/state';

export const useAuthenticatedAxios =  (apiRequest) => {
  const { user } = useContext(StateContext);
  apiRequest['headers'] = {
    ...apiRequest?.headers,
    'Authorization': `Bearer ${user?.access_token}`,
  };

  return useAxios(apiRequest, {
    manual: true,
  });
};  