import { getItem, setItem, deleteItem } from './storage';
import moment from 'moment';
import jwtDecode from "jwt-decode";

// the following fields are available:
// { aud, email, email_verified, exp, iat, iss, name, nickname
//   nonce, picture, sub, updated_at }
export const createUserInfo = (id_token) => {
  const decodedJwtIdToken = jwtDecode(id_token);
  // const fullName = decodedJwtIdToken["https://crm.kb.com/name"];
  return {
    ...decodedJwtIdToken,
    primaryUserId: decodedJwtIdToken.sub,
  };
}

export const getUserInfo = async () => {
  const id_token = await getIdToken();
  const access_token = await getAccessToken();
  if (id_token && access_token) {
    const userInfo = createUserInfo(id_token);
    return { ...userInfo, access_token, id_token };
  }
  return null;
}

export const getIdToken = async () => {
  let token = await getItem('idToken');
  const tokenExpirationDate = await getItem('tokenExpirationDate');
  if (token != null) {
    const cutoff = moment.unix(parseInt(tokenExpirationDate)).subtract(15, 'minutes');
    if (moment().isAfter(cutoff)) {
      console.log("Token is expiring soon. Refreshing...", tokenExpirationDate);
      token = null;
    } else {
      // console.log(cutoff);
    }
  }
  return token;
};

export const getAccessToken = async () => {
  let token = await getItem('accessToken');
  const tokenExpirationDate = await getItem('tokenExpirationDate');
  if (token != null) {
    const cutoff = moment.unix(parseInt(tokenExpirationDate)).subtract(15, 'minutes');
      if (moment().isAfter(cutoff)) {
      console.log("Token is expiring soon. Refreshing...", tokenExpirationDate);
      token = null;
    } else {
      // console.log(cutoff);
    }
  }
  return token;
};

export const clearUserInfo = async () => {
  ['accessToken', 'idToken', 'tokenExpirationDate']
      .forEach(async (key) => await deleteItem(key));
}

export const saveIdToken = async (accessToken, idToken, expirationDate) => {
  const expdate = moment.unix(expirationDate);
  await setItem('accessToken', accessToken);
  await setItem('idToken', idToken);
  await setItem('tokenExpirationDate', '' + expirationDate);
};
