export const white = '#FFFFFF';
export const black = '#000000';
export const blue = '#24A7F8';
export const blueButton = '#00B5EC';
export const offWhite = '#F8F9FC';
export const paleBlue = '#E2F4FF';
export const lightBlue = '#CDE8FC';
export const green = '#5BC67E';
export const orange = '#FDA256';
export const lightGrey = '#D1D1D1';
export const red = '#FF6666';
export const greyShadow = '#00000021';
export const gray = '#BEBEBE';

export const baseText = '#444';
export const secondaryText = '#999';
export const whiteText = white;
export const whiteBackground = white;
export const authPageBackground = offWhite;
export const mainPageBackground = white;
export const buttonBackground = blueButton;
export const smallButtonBackground = paleBlue;
export const smallButtonText = blue;
export const infoCard = lightBlue;
export const netPayTextColor = green;
export const deductionsTextColor = orange;
export const balanceTaxes = lightGrey;
export const taxPaid = red;
export const shadowColor = greyShadow;
export const borderColor = gray;
export const headerColor = blue;
