import React from 'react'
import { SafeAreaView } from 'react-native-safe-area-context'
import ModelMetaDataMap from '../../models'
import ErrorScreen from '../ErrorScreen'

const ModelViewScreen = (props) => {
  const { route, navigation } = props;
  const { model, item } = route.params;
  const metadata = ModelMetaDataMap[model];

  if (metadata == undefined) {
    return <ErrorScreen message={`Metadata not configured for ${model}`} />;
  }

  const ItemView = metadata.createItemView(item, metadata, navigation );

  return (
    <SafeAreaView style={{flex: 1, padding: 5 }}>
      <ItemView />
    </SafeAreaView>
  )
}

export default ModelViewScreen

