import { TextInput } from 'react-native';
import styled from 'styled-components/native';

export const FieldWrapper = styled.View`
  marginTop: 5px;
  marginBottom: 10px;
`;

export const LabelText = styled.Text`
  font-size: 12px;
  color: ${props => props.theme.colors.subText};
  marginBottom: 5px;
  paddingHorizontal: 5px;
  textTransform: uppercase;
`;

export const StyledInput = styled(TextInput)`
  minHeight: 40px;
  padding: 10px 5px;
  fontSize: 18px;
  borderBottomWidth: 1px;
  borderColor: #bcbcbc;
`;

export const ErrorText = styled.Text`
  color: red;
  fontSize: 12px;
  marginTop: 5px;
`;

export const IconWrapper = styled.View`
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

export const FontView = styled.View`
  margin-left: 25px;
`
