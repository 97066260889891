import { useMutation } from '@apollo/client';
import React from 'react';
import { ActivityIndicator, Text, View } from 'react-native';
import styles from '../../assets/css/styles';
import ContentTable from '../../components/ContentTable';
import ModelMetaDataMap, { useSafeQuery } from '../../models';
import { deleteIndexRequest } from '../../search/searchIndexApi';
import { useAuthenticatedAxios } from '../../shared/api';

const ModelListScreen = (props) => {
  const { model } = props.route.params;
  const metadata = ModelMetaDataMap[model];
  const [
    {
      data: postData,
      loading: postLoading,
      error: postError,
      response: postResponse,
    },
    executePost,
  ] = useAuthenticatedAxios(deleteIndexRequest());

  if (metadata == undefined) {
    return (
      <View>
        <Text>Metadata not configured for {model}</Text>
      </View>
    );
  }

  const { loading, error, data, client } = useSafeQuery(metadata.fetchQuery);

  const [deleteEntity, { result }] = useMutation(metadata.deleteMutation);

  if (loading) {
    return (
      <View style={[styles.container, styles.horizontal]}>
        <ActivityIndicator size='large' color='#0000ff' />
      </View>
    );
  }

  if (error) {
    console.log(error);
    return (
      <View>
        <Text>{error.message}</Text>
      </View>
    );
  }

  const items = (data && data[metadata.queryAll]) || [];

  const onEdit = (rowData, index) => {
    console.log(`editing for index ${index}`);
    const params = {
      mode: 'edit',
      title: `Edit ${metadata.name}`,
      data: rowData,
      model,
      index: index + 1,
      onMoveNext,
    };
    props.navigation.navigate(metadata.editScreen, params);
  };

  const onAdd = () => {
    const params = { mode: 'add', title: `Add ${metadata.name}`, model };
    props.navigation.navigate(metadata.addScreen, params);
  };

  const onDelete = (rowData) => {
    console.log(`deleting ${metadata.name}: ${rowData.id}`);
    deleteEntity({
      variables: { filter: { id: [rowData.id] } },
      update: (cache) => {
        console.log(`updating cache after delete for id: ${rowData.id}!`);
        const existingItems = cache.readQuery({ query: metadata.fetchQuery });
        const evicted = cache.evict(cache.identify(rowData));
        const newItems = existingItems[metadata.queryAll].filter(
          (t) => t.id !== rowData.id
        );
        let data = {};
        data[metadata.queryAll] = newItems;
        cache.writeQuery({
          query: metadata.fetchQuery,
          data,
        });
      },
      onCompleted: (data) => {
        console.log(`${metadata.name} deleted successfully!`);
      },
    });
    executePost({
      data: {
        objectId: rowData.id,
      },
    });
    if(postError){
      console.log(postError);
    }
  };

  const onMoveNext = (nextIndex) => {
    console.log(`moving to next record ${nextIndex}`);
    const rowData = items && items.length > nextIndex ? items[nextIndex] : null;
    if (rowData) {
      // onEdit(rowData, nextIndex);
    } else {
      console.log(`sorry no more items ${items.length}`);
    }
  };

  const onView = (item) => {
    props.navigation.navigate(metadata.viewScreen, { model, item });
  };

  return (
    <ContentTable
      metadata={metadata}
      items={items}
      client={client}
      onAdd={onAdd}
      onEdit={onEdit}
      onDelete={onDelete}
      onView={onView}
    />
  );
};

export default ModelListScreen;
