import React from 'react'
import { StyleSheet, View, Text, TouchableOpacity, ScrollView } from 'react-native'
import {
  Field,
  Relation,
  ModelMetadata,
  CreatedDateAutoComputePolicy,
  ModifiedDateAutoComputePolicy,
  BlankStringAutoComputePolicy,
} from '../model_defs';
import * as g from './graphql';
import { validationSchema, createModelForm } from './form';
import { createItemView, createListItemRenderer, createItemDetailView } from './view';

export const MODEL_NAME = 'Asset'
const MODEL_PLURAL = 'Assets'

const asset_type = new Relation(
  "id",
  "name",
  "queryAssetType",
  g.QUERY_ASSET_TYPES
);

const intent = new Relation(
  "id",
  "name",
  "queryIntent",
  g.QUERY_INTENTS
);

const persona = new Relation(
  "id",
  "name",
  "queryPersona",
  g.QUERY_PERSONAS
);

const module = new Relation(
  "id",
  "name",
  "queryModule",
  g.QUERY_MODULES,
  { dropdownWidth: '50%' }
);

const fields = [
  new Field('module', 'Module', { showInList: true, relation: module }),
  new Field('asset_type', 'Asset Type', { showInList: true, relation: asset_type }),
  new Field('intent', 'Intent', { showInList: true, relation: intent }),
  new Field('persona', 'Persona', { showInList: true, relation: persona }),
  new Field('title', 'Title', { showInList: true, flex: 2, nativeTitle: true }),
  new Field('description', 'Description', { autoComputePolicy: BlankStringAutoComputePolicy }),
  new Field('url', 'URL'),
  new Field('image', 'Image'),
  new Field('remarks', 'Remarks', { autoComputePolicy: BlankStringAutoComputePolicy }),
  new Field('created', 'Created On', { showInList: true, autoComputePolicy: CreatedDateAutoComputePolicy }),
  new Field('modified', 'Last Modified On', { showInList: true, autoComputePolicy: ModifiedDateAutoComputePolicy }),
];

export const MetadataInstance = new ModelMetadata(MODEL_NAME, {
  icon: 'cubes',
  plural: MODEL_PLURAL,
  fetchQuery: g.QUERY_ALL,
  getItemQuery: g.QUERY_ITEM,
  addMutation: g.ADD_MUTATION,
  updateMutation: g.UPDATE_MUTATION,
  deleteMutation: g.DELETE_MUTATION,
  updateCache: g.updateCache,
  fields: fields,
  validationSchema,
  createModelForm,
  createItemView,
  createItemDetailView,
  createListItemRenderer,
});
