import React from 'react'
import { View, ActivityIndicator, StyleSheet } from 'react-native'
import { getIdToken } from "../shared/auth";
import { useStateValue } from "../shared/state";

const SplashScreen = (props) => {
  return (
    <View style={[styles.container, styles.horizontal]}>
      <ActivityIndicator size="large" color="#0000ff" />
    </View>
  );

}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  }
});

export default SplashScreen;