import React from 'react';
import { View } from 'react-native';
import { BodyText, Heading1, SubText } from '../styles/components';

const FeatureViewComponent = ({ feature }) => {
  return (
    <View style={{ alignSelf: 'flex-start', justifyContent: 'flex-start', flexWrap: 'wrap' }}>
      <SubText>{feature.code}</SubText>
      <Heading1>{feature.name}</Heading1>
      <BodyText>{feature.description}</BodyText>
      <SubText>BENEFIT</SubText>
      <BodyText>{feature.benefit}</BodyText>
      <SubText>ADVANTAGE</SubText>
      <BodyText>{feature.benefit}</BodyText>
    </View>
  );
};

export default FeatureViewComponent;
