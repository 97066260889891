import { useQuery } from '@apollo/client';
import React from 'react';
import { Image, Text, View } from 'react-native';
import styled from 'styled-components/native';
import FloatingActionButtons from '../../components/FloatingActionButtons';
import { ActivityIndicator } from '../../styles/components';

const H1 = styled.Text`
  font-size: 24px;
  margin: 15px 0;
`;

const StyledView = styled.View`
  flex: 1;
  flex-direction: column;
  margin: 0;
  padding: 0 5px;
  height: 100%;
  align-self: stretch;
`;

const ModuleText = styled.Text`
  font-size: 16px;
  text-transform: uppercase;
  color: #666;
  marginBottom: 15px;
`;

const BodyText = styled.Text`
  font-size: 18px;
`;

const ItemViewComponent = ({ id, metadata, navigation }) => {
  const { loading, error, data } = useQuery(metadata.getItemQuery, { variables: { id } });

  if (loading) {
    return (
      <ActivityIndicator size="large" />
    );
  }

  if (error) {
    console.log(error);
    return (
      <View>
        <Text>{error.message}</Text>
      </View>
    )
  }

  const item = data && data[metadata.queryAll] && data[metadata.queryAll][0] || {};

  return (
    <StyledView>
      <ModuleText>{item.asset_type.name}: {item.module.name}</ModuleText>
      {item.image && <Image
        style={{ aspectRatio: 4 / 3 }}
        source={{
          uri: item.image,
        }}
      />}
      <H1>{item.title}</H1>
      <BodyText style={{ marginBottom: 40 }}>{item.description}</BodyText>
      <ModuleText>{item.intent.name}/{item.persona.name}</ModuleText>
      <FloatingActionButtons style={{ position: 'absolute', bottom: 30, right: 30 }} item={item} metadata={metadata} navigation={navigation} />
    </StyledView>
  )
}

export default ItemViewComponent;
