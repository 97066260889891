import { useQuery } from '@apollo/client';
import { useEffect, useMemo, useState } from 'react';
import { MetadataInstance as AreaMetaData, MODEL_NAME as AREA } from './area';
import { MetadataInstance as AssetMetaData, MODEL_NAME as ASSET } from './asset';
import { MetadataInstance as FeatureMetaData, MODEL_NAME as FEATURE } from './feature';
import { ADD_MODE, EDIT_MODE } from './model_defs';
import { MetadataInstance as ModuleMetaData, MODEL_NAME as MODULE } from './module';

const Models = [
  AssetMetaData,
  ModuleMetaData,
  AreaMetaData,
  FeatureMetaData,
]

const ModelMetaDataMap = Models.reduce((map, model) => {
  map[model.name] = model;
  return map;
}, {})

const getModelIcon = modelName => {
  const metadata = ModelMetaDataMap[modelName];
  return metadata ? metadata.icon : 'asterisk';
}

const useSafeQuery = (query, variables) => {
  // we memoize to prevent infinite re-rendering loop
  // see: https://github.com/apollographql/react-apollo/issues/3644
  const QUERY = useMemo(() => query, []);
  return useQuery(QUERY, { variables });
}

const useColumnDefs = (client, metadata, variables, guard, callback) => {
  const [columnDefs, setColumnDefs] = useState(null);

  useEffect(() => {
    (async function getDefs() {
      const columnDefs = await metadata.getColumnDefs(client, variables);
      if (callback) {
        callback(columnDefs);
      }
      setColumnDefs(columnDefs);
    })();
  }, guard)

  return { columnDefs }
}


export {
  EDIT_MODE,
  ADD_MODE,
  ASSET,
  MODULE,
  AREA,
  FEATURE,
  Models,
  useSafeQuery,
  useColumnDefs,
  getModelIcon,
};

export default ModelMetaDataMap;
