import React from 'react'
import { Text, TouchableWithoutFeedback } from 'react-native'
import { Icon, BodyText, ActivityIndicator, ModuleView, ModuleWrapper } from '../styles/components'

export const ModuleItem = ({ item, onPress }) => {
  return (
    <TouchableWithoutFeedback onPress={() => onPress(item)}>
      <ModuleWrapper>
        <ModuleView>
          <Icon name={item.icon} />
          <BodyText style={{ textAlign: 'center' }}>{item.name}</BodyText>
        </ModuleView>
      </ModuleWrapper>
    </TouchableWithoutFeedback>
  )
}
