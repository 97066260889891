import { useMutation } from '@apollo/client';
import { FontAwesome5 } from '@expo/vector-icons';
import React, { useEffect, useRef } from 'react';
import { Button, ScrollView, StyleSheet, Text, TouchableOpacity, View } from 'react-native';
import { useColumnDefs } from '../models';
import { EDIT_MODE } from '../models/model_defs';
import ModelGrid from './ModelGrid';

const EDIT_ICON = "edit";
const DELETE_ICON = "trash-alt";


const Heading = ({ title, onAdd }) => {
  return (
    <View style={styles.headingContainer}>
      <Text style={styles.h1}></Text>
      <Button title="Add" onPress={onAdd} />
    </View>
  )
}

const IconButtonCell = ({ icon, id, lookupItem, index, onPress }) => {
  return (
    <TouchableOpacity onPress={() => {
      const item = lookupItem(id);
      onPress(item, index);
    }}>
      <View style={styles.icon_btn}>
        <FontAwesome5 name={icon} size={20} color="black" style={{ marginHorizontal: 3 }} />
      </View>
    </TouchableOpacity>
  )
}

const ButtonSet = ({ id, index, lookupItem, onEdit, onDelete }) => {
  return (
    <View style={{ flexDirection: 'row' }}>
      <IconButtonCell icon={EDIT_ICON} id={id} index={index} lookupItem={lookupItem} onPress={onEdit} />
      <IconButtonCell icon={DELETE_ICON} id={id} index={index} lookupItem={lookupItem} onPress={onDelete} />
    </View>
  )
}

const getButtonsColumnDef = (lookupItem, onEdit, onDelete) => {
  return {
    headerName: '',
    field: 'buttons',
    flex: 1,
    cellRendererFramework: function (params) {
      return <ButtonSet id={params.node.id} lookupItem={lookupItem} onEdit={onEdit} onDelete={onDelete} index={params.rowIndex} />
    }
  }
}

const ContentTable = ({ metadata, client, items, onView, onAdd, onEdit, onDelete }) => {
  const itemsRef = useRef(items);

  const rowData = items.map(item => metadata.getFlattenedValues(item, EDIT_MODE));
  const lookupItem = (id) => itemsRef.current.find(it => it.id === id);

  const [mutate, { result }] = useMutation(metadata.updateMutation, {
    onCompleted: (data) => {
      console.log(`${metadata.name}: record updated successfully!`);
    }
  });

  useEffect(() => {
    itemsRef.current = items;
  }, [items])

  const buttonDef = getButtonsColumnDef(lookupItem, onEdit, onDelete);
  const { columnDefs } = useColumnDefs(client, metadata, {}, [client], (defs) => {
    defs.push(buttonDef);
  });

  return (
    <ScrollView style={styles.container}>
      <Heading onAdd={onAdd} />
      <View className="ag-theme-balham" style={{ height: '60vh', width: '100%' }}>
      <ModelGrid
          enableFilter
          columnDefs={columnDefs}
          rowData={rowData}
          metadata={metadata}
          mutate={mutate}
        />
      </View>
    </ScrollView>
  );
}


const styles = StyleSheet.create({
  headingContainer: { flexDirection: 'row', justifyContent: 'space-between', marginBottom: 10 },
  h1: { fontSize: 22, margin: 5, marginRight: 20 },
  container: { margin: 5, flex: 1, paddingVertical: 5, paddingHorizontal: 15 },
  head: { flexDirection: 'row', height: 40, backgroundColor: '#f1f8ff' },
  text: { margin: 6 },
  row: { flexDirection: 'row', backgroundColor: '#fff' },
  btn: { width: 58, height: 18, backgroundColor: '#78B7BB', borderRadius: 2 },
  btnText: { textAlign: 'center', color: '#fff' },
  icon_btn: { justifyContent: 'center', },
});

export default ContentTable;
