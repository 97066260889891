import { useQuery } from '@apollo/client';
import React from 'react';
import { ScrollView, Text, View } from 'react-native';
import FloatingActionButtons from '../../components/FloatingActionButtons';
import { ActivityIndicator, FieldView } from '../../styles/components';

const ItemViewComponent = ({ id, metadata, navigation }) => {
  const { loading, error, data } = useQuery(metadata.getItemQuery, { variables: { id } });

  if (loading) {
    return <ActivityIndicator size='large' />;
  }

  if (error) {
    console.log(error);
    return (
      <View>
        <Text>{error.message}</Text>
      </View>
    );
  }

  const item = (data && data[metadata.queryAll] && data[metadata.queryAll][0]) || {};
  return (
    <View>
      <ScrollView>
        <FieldView label='Code' value={item.code} />
        <FieldView label='Name' value={item.name} />
        <FieldView label='Icon' value={item.icon} />
        <FieldView label='Strap Line' value={item.strap_line} />
        <FieldView label='Description' value={item.description} />
      </ScrollView>
      <FloatingActionButtons item={item} metadata={metadata} navigation={navigation} />
    </View>
  );
};

export default ItemViewComponent;
