import { useQuery, gql } from '@apollo/client';

const ModulesFragment = gql`
  fragment ModulesFragment on Module {
    id
    code
    name
    strap_line
    description
    created
    is_published
    icon
    remarks
    areas {
      id
      name
    }
  }
`;

export const QUERY_ALL = gql`
  query AllModules {
    queryModule(order: {asc: name}) {
      ...ModulesFragment
    }
  }
  ${ModulesFragment}
`;

export const ADD_MUTATION = gql`
  mutation addModule($record: AddModuleInput!) {
    addModule(input: [$record]) {
      module {
        ...ModulesFragment
      }
    }
  }
  ${ModulesFragment}
`;

export const UPDATE_MUTATION = gql`
  mutation UpdateModule($patch: UpdateModuleInput!) {
    updateModule(input: $patch) {
      module {
        ...ModulesFragment
      }
    }
  }
  ${ModulesFragment}
`;

export const DELETE_MUTATION = gql`
mutation deleteModule($filter: ModuleFilter!) {
  deleteModule(filter: $filter) {
    msg
  }
}
`;

export const QUERY_ITEM = gql`
  query ReadModule($id: ID!) {
    queryModule(filter: {id: [$id]}) {
      ...ModulesFragment
    }
  }
  ${ModulesFragment}
`;

export const updateCache = (cache, { data: { addModule } }) => {
  cache.modify({
    fields: {
      modules(existingItems = []) {
        const newItemRef = cache.writeFragment({
          data: addModule,
          fragment: ModulesFragment,
        });
        return [...existingItems, newItemRef];
      }
    }
  });
}
