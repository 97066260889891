import React, { useState } from 'react';
import { StyleSheet, View, TextInput, TouchableOpacity, Keyboard, Platform } from 'react-native';
import PropTypes from 'prop-types';
import { connectSearchBox } from 'react-instantsearch-native';
import { FontAwesome5 } from '@expo/vector-icons';

const CLOSE_ICON = 'arrow-left';
const SEARCH_ICON = 'search';

const Icon = ({iconName}) => <FontAwesome5 name={iconName} size={26} color="lightgray" />

const SearchBox = ({ currentRefinement, refine, setShowHits, showHits }) => {
  const CloseButton = () => (
    <TouchableOpacity
      style={styles.button}
      onPress={() => {
        setShowHits(false);
        Keyboard.dismiss()}}>
      <Icon iconName={CLOSE_ICON}/>
    </TouchableOpacity>
  );

  const SearchButton = () => (
    <TouchableOpacity
      style={styles.button}
      onPress={() => setShowHits(true)}>
      <Icon iconName={SEARCH_ICON} />
    </TouchableOpacity>
  );

  return (
    <View style={styles.container}>
      {showHits ? <CloseButton /> : <SearchButton />}
      <TextInput
        style={styles.input}
        onChangeText={value => refine(value)}
        onFocus={() => setShowHits(true)}
        value={currentRefinement}
        placeholder="Search"
      />
    </View>
  );
};

const styles = StyleSheet.create({
  button: {
    alignSelf: 'center',
    alignItems: 'center',
    padding: 2,
    flex: 1,
  },
  container: { 
    flexDirection: 'row',
    borderRadius: 4,
    borderWidth: 1,
    borderColor: '#ddd',
  },
  input: {
    height: 48,
    padding: 12,
    width: '80%',
    flex: 6,
    ...Platform.select({
      web:  {
        flex: 15,
        outlineWidth: 0
      },
    })
    }
});

SearchBox.propTypes = {
  currentRefinement: PropTypes.string.isRequired,
  refine: PropTypes.func.isRequired,
};
  
export default connectSearchBox(SearchBox);