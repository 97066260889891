import { useQuery } from '@apollo/client';
import { FontAwesome5 } from '@expo/vector-icons';
import React, { useContext, useEffect, useState } from 'react';
import { Dimensions, Modal, Platform, View } from 'react-native';
import { SafeAreaView } from 'react-native-safe-area-context';
import styled from 'styled-components/native';
import AddModelComponent from '../components/AddModelComponent';
import { QUERY_ITEM } from '../models/feature/graphql';
import { StateContext } from '../shared/state';
import {
  ActivityIndicator,
  BodyText,
  ErrorMessage,
  Heading1,
  HeadingWrapper,
  SectionWrapper,
  SubText,
} from '../styles/components';
import ErrorScreen from './ErrorScreen';

const window = Dimensions.get('window');

const ModalContainerView = styled.View`
  align-self: center;
  align-items: center;
  margin: 0;
  background-color: #e3f3f7;
  border-radius: 10px;
  padding: 15px;
  shadow-color: #000;
  shadow-offset: {
    width: 0;
    height: 2px;
  }
  shadow-opacity: 0.25;
  shadow-radius: 3.84px;
  elevation: 5;
`;

const EditFeatureModal = (props) => {
  const { id, modalVisible, setModalVisible, onSave } = props;
  const formOptions = {
    quickAdd: true,
    referenceFilters: { area: { moduleFilter: { id: [id] } } },
  };
  const width = Platform.OS == 'web' ? '500px' : '100%';
  const maxHeight = 650;
  return (
    <Modal
      animationType='slide'
      transparent={true}
      visible={modalVisible}
      style={{ flex: 1 }}
      onRequestClose={() => setModalVisible(!modalVisible)}
    >
      <ModalContainerView style={{ width, maxHeight }}>
        <HeadingWrapper style={{ margin: 25, width: '100%', justifyContent: 'space-between' }}>
          <Heading1>Add Feature</Heading1>
          <FontAwesome5 name='times' size={32} onPress={() => setModalVisible(!modalVisible)} />
        </HeadingWrapper>
        <AddModelComponent
          model='Feature'
          onSave={onSave}
          formOptions={formOptions}
          localRefetchQueries={[QUERY_BY_MODULE]}
        />
      </ModalContainerView>
    </Modal>
  );
};

/*
<EditFeatureModal
        id={module.id}
        modalVisible={modalVisible}
        setModalVisible={setModalVisible}
        onSave={onSave}
      />
 */

const FeatureScreen = (props) => {
  const [dimensions, setDimensions] = useState({ window });
  const [modalVisible, setModalVisible] = useState(false);
  const { user, setUser } = useContext(StateContext);
  const { navigation } = props;
  const { featureId } = props.route.params;

  const isLargeScreen = dimensions.window.width >= 768;
  const width = isLargeScreen && Platform.OS == 'web' ? '50%' : '100%';

  const onChange = ({ window }) => {
    setDimensions({ window });
  };

  useEffect(() => {
    Dimensions.addEventListener('change', onChange);
    return () => {
      Dimensions.removeEventListener('change', onChange);
    };
  });

  if (featureId == undefined) {
    return <ErrorScreen message={`Feature ID not supplied!`} />;
  }

  console.log(feature);

  const { loading, error, data } = useQuery(QUERY_ITEM, { variables: { id: featureId } });

  if (loading) {
    return <ActivityIndicator size='large' />;
  }

  if (error) {
    return <ErrorMessage message={error.message} />;
  }

  const feature = (data && data.queryFeature[0]) || {};
  console.log('got feature ', feature);

  const onSave = () => {
    setModalVisible(false);
  };

  return (
    <SafeAreaView style={{ flex: 1 }}>
      <HeadingWrapper style={{ marginTop: -5 }}>
        <SectionWrapper>
          <SubText>
            {feature.area.module.code}/{feature.area.code}/{feature.code}
          </SubText>
          <Heading1>{feature.name}</Heading1>
        </SectionWrapper>
      </HeadingWrapper>
      <View>
        <SectionWrapper>
          {feature.strapLine && <BodyText>{feature.strapline}</BodyText>}
          <BodyText>{feature.description}</BodyText>
        </SectionWrapper>
      </View>
    </SafeAreaView>
  );
};

export default FeatureScreen;
