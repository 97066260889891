import { useMutation } from '@apollo/client';
import { Formik } from 'formik';
import _ from 'lodash';
import React from 'react';
import { ScrollView } from 'react-native';
import { StyledSafeAreaView } from '../../components';
import ModelMetaDataMap, { ADD_MODE, EDIT_MODE } from '../../models';
import { saveIndexRequest } from '../../search/searchIndexApi';
import { useAuthenticatedAxios } from '../../shared/api';

const AddScreen = (props) => {
  const { navigation, route } = props;
  const { model } = route.params;
  const metadata = ModelMetaDataMap[model];
  const [
    {
      data: postData,
      loading: postLoading,
      error: postError,
      response: postResponse,
    },
    executePost,
  ] = useAuthenticatedAxios(saveIndexRequest());

  if (metadata == undefined) {
    return (
      <ErrorScreen
        message={`Misconfiguration: metadata not available for ${model}!`}
      />
    );
  }

  const { createModelForm, validationSchema, refetchQueries } = metadata;
  const initialValues = metadata.getFlattenedValues({});
  const form = createModelForm(metadata);

  const [mutate, { result }] = useMutation(metadata.addMutation, {
    awaitRefetchQueries: true,
    update: metadata.updateCache,
    refetchQueries,
    onCompleted: (data) => {
      console.log(`${metadata.name}: record added successfully!`);
      navigation.goBack();
    },
  });

  const saveIndex = (objectId) => {
    const type = _.lowerCase(model);
    executePost({
      data: {
        type,
        objectId,
      },
    });
    if (postError) {
      console.log(postError);
    }
  };

  return (
    <StyledSafeAreaView>
      <ScrollView>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            const record = metadata.getShapedValues(values, ADD_MODE);
            // console.log(record);
            mutate({ variables: { record } })
              .then(({ data }) => {
                const recordData = Object.values(data)[0]
                const addedRecord = Object.values(recordData)[0];
                saveIndex(addedRecord[0]?.id);
              })
              .catch((e) => {
                console.log('${metadata.name}: add mutation failed', e);
              });
          }}
        >
          {form}
        </Formik>
      </ScrollView>
    </StyledSafeAreaView>
  );
};

const EditScreen = (props) => {
  const { navigation, route, client } = props;
  const { data, model, index, onMoveNext } = route.params;
  const metadata = ModelMetaDataMap[model];
  const [
    {
      data: postData,
      loading: postLoading,
      error: postError,
      response: postResponse,
    },
    executePost,
  ] = useAuthenticatedAxios(saveIndexRequest());

  if (metadata == undefined) {
    return (
      <ErrorScreen
        message={`Misconfiguration: metadata not available for ${model}!`}
      />
    );
  }

  const { createModelForm, validationSchema, refetchQueries } = metadata;
  const initialValues = metadata.getFlattenedValues(data);
  const form = createModelForm(metadata);

  const [mutate, { result }] = useMutation(metadata.updateMutation, {
    onCompleted: (data) => {
      console.log(`${metadata.name}: record updated successfully!`);
      navigation.goBack();
      if (onMoveNext) {
        onMoveNext(index);
      }
    },
  });

  const saveIndex = (objectId) => {
    const type = _.lowerCase(model);
    executePost({
      data: {
        type,
        objectId,
      },
    });
    if (postError) {
      console.log(postError);
    }
  };

  return (
    <StyledSafeAreaView>
      <ScrollView>
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values) => {
            console.log(values);
            const record = metadata.getShapedValues(values, EDIT_MODE);
            const patch = { filter: { id: [initialValues.id] }, set: record };

            console.log(patch);
            mutate({ variables: { patch } })
              .then(() => saveIndex(initialValues.id))
              .catch((e) => {
                console.log(`${metadata.name}: update mutation failed`, e);
              });
          }}
        >
          {form}
        </Formik>
      </ScrollView>
    </StyledSafeAreaView>
  );
};

export { AddScreen, EditScreen };

