import { createDrawerNavigator } from '@react-navigation/drawer';
import { createStackNavigator } from '@react-navigation/stack';
import React, { useContext, useEffect, useState } from 'react';
import { Dimensions } from 'react-native';
import AdminScreen from '../screens/admin/AdminScreen';
import { AddScreen, EditScreen } from '../screens/admin/ModelEditScreen';
import ModelListScreen from '../screens/admin/ModelListScreen';
import ModelViewScreen from '../screens/admin/ModelViewScreen';
import SchemaScreen from '../screens/admin/SchemaScreen';
import SystemMaintenanceScreen from '../screens/admin/SystemMaintenanceScreen';
import AreaScreen from '../screens/AreaScreen';
import FeatureScreen from '../screens/FeatureScreen';
import HomeScreen from '../screens/HomeScreen';
import LoginScreen from '../screens/LoginScreen';
import ModuleScreen from '../screens/ModuleScreen';
import NotFoundScreen from '../screens/NotFoundScreen';
import ProfileScreen from '../screens/ProfileScreen';
import SplashScreen from '../screens/SplashScreen';
import { getUserInfo } from '../shared/auth';
import { StateContext } from '../shared/state';
import BurgerMenuButton from './BurgerMenuButton';



const ERROR_SCREEN = "ErrorScreen";

const window = Dimensions.get("window");
const Drawer = createDrawerNavigator();
const Stack = createStackNavigator();

const navOptions = ({ route }) => ({ title: route.params?.title || '' });

const AdminStack = ({ isLargeScreen }) => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Admin" component={AdminScreen} options={({ navigation }) => ({
          headerLeft: () => (isLargeScreen ? null : (<BurgerMenuButton navigation={navigation} />)),
        })} />
      <Stack.Screen name="SchemaScreen" component={SchemaScreen} options={navOptions} />
      <Stack.Screen name="SystemMaintenanceScreen" component={SystemMaintenanceScreen} options={navOptions} />
      <Stack.Screen name="ListScreen" component={ModelListScreen} options={navOptions} />
      <Stack.Screen name="AddScreen" component={AddScreen} options={navOptions} />
      <Stack.Screen name="EditScreen" component={EditScreen} options={navOptions} />
      <Stack.Screen name="ItemViewScreen" component={ModelViewScreen} options={navOptions} />
    </Stack.Navigator>
  )
}

const ProfileStack = ({ isLargeScreen }) => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="ProfileScreen" component={ProfileScreen} options={({ navigation }) => ({
          headerLeft: () => (isLargeScreen ? null : (<BurgerMenuButton navigation={navigation} />)),
        })} />
    </Stack.Navigator>
  );
}

const HomeStack = ({ isLargeScreen }) => {
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="Home" component={HomeScreen} options={({ navigation }) => ({
          headerLeft: () => (isLargeScreen ? null : (<BurgerMenuButton navigation={navigation} />)),
        })} />
      <Stack.Screen name="ModuleScreen" component={ModuleScreen} options={navOptions} />
      <Stack.Screen name="FeatureScreen" component={FeatureScreen} options={navOptions} />
      <Stack.Screen name="AreaScreen" component={AreaScreen} options={navOptions} />
      <Stack.Screen name="NotFound" component={NotFoundScreen} options={navOptions} />
    </Stack.Navigator>
  );
}

const DrawerNavigator = props => {
  const { isLargeScreen } = props;
  return (
    <Drawer.Navigator
      initialRouteName="HomeStack"
      drawerType={isLargeScreen ? 'permanent' : 'front'}>
      <Drawer.Screen name="HomeStack" options={{ title: 'Home' }}>
        {props => <HomeStack {...props} isLargeScreen={isLargeScreen} />}
      </Drawer.Screen>
      <Drawer.Screen name="AdminStack" options={{ title: 'Admin' }}>
        {props => <AdminStack {...props} isLargeScreen={isLargeScreen} />}
      </Drawer.Screen>
      <Drawer.Screen name="ProfileStack" options={{ title: 'Profile' }}>
        {props => <ProfileStack {...props} isLargeScreen={isLargeScreen} />}
      </Drawer.Screen>
    </Drawer.Navigator>
  )
}

const MenuDrawer = (props) => {
  const [dimensions, setDimensions] = useState({ window });
  const { user, setUser } = useContext(StateContext);
  const [loadingUser, setLoadingUser] = useState(true);

  const isLargeScreen = false; // dimensions.window.width >= 768;

  const onChange = ({ window }) => {
    setDimensions({ window });
  };

  useEffect(() => {
    Dimensions.addEventListener("change", onChange);
    return () => {
      Dimensions.removeEventListener("change", onChange);
    };
  });

  useEffect(() => {
    let mounted = true;
    async function _getUserInfo() {
      const userInfo = await getUserInfo();
      if (userInfo != null) {
        setUser(userInfo);
      }
      if (mounted) {
        setLoadingUser(false);
      }
    }
    _getUserInfo();
    return function cleanup() {
      mounted = false
    }
  }, [loadingUser]);

  if (loadingUser && user) {
    return (
      <Stack.Navigator>
        <Stack.Screen name="splash" component={SplashScreen} options={{ title: '' }} />
      </Stack.Navigator>
    )
  }

  if (!loadingUser && !user?.id_token) {
    return (
      <Stack.Navigator>
        <Stack.Screen name="login" component={LoginScreen} options={{ title: '' }} />
        {/* <Stack.Screen name="ErrorScreen" component={ERROR_SCREEN} /> */}
      </Stack.Navigator>
    )
  }

  return <DrawerNavigator isLargeScreen={isLargeScreen} />;
}

export default MenuDrawer;