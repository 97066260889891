import { useQuery } from '@apollo/client';
import React from 'react';
import { ScrollView, View } from 'react-native';
import FloatingActionButtons from '../../components/FloatingActionButtons';
import { ActivityIndicator, ErrorMessage, FieldView } from '../../styles/components';

const ItemViewComponent = ({ id, metadata, navigation }) => {
  const { loading, error, data } = useQuery(metadata.getItemQuery, { variables: { id } });

  if (loading) {
    return <ActivityIndicator size='large' />;
  }

  if (error) {
    return <ErrorMessage message={error.message} />;
  }

  const item = (data && data[metadata.queryAll] && data[metadata.queryAll][0]) || {};

  return (
    <View>
      <ScrollView>
        <FieldView label='Area' value={item.area.name} />
        <FieldView label='Code' value={item.code} />
        <FieldView label='Name' value={item.name} />
        <FieldView label='Icon' value={item.icon} />
        <FieldView label='Strap Line' value={item.strap_line} />
        <FieldView label='Description' value={item.description} />
        <FieldView label='Remarks' value={item.remarks} />
        <FieldView label='Benefit' value={item.benefit} />
        <FieldView label='Advantage' value={item.advantage} />
        <FieldView label='Frequency' value={item.frequency} />
        <FieldView label='Commonality' value={item.commonality} />
        <FieldView label='Priority' value={item.priority} />
        <FieldView label='Impact' value={item.impact} />
      </ScrollView>
      <FloatingActionButtons item={item} metadata={metadata} navigation={navigation} />
    </View>
  );
};

export default ItemViewComponent;
