import React from 'react';
import { SafeAreaView, StyleSheet, Text, FlatList, TouchableOpacity } from 'react-native';
import { Models } from '../../models'
import { MediumIcon, HeadingWrapper, Chevron } from '../../styles/components'

const SchemaScreen = (props) => {
  const renderItem = ({ item }) => {
    const openListingPage = (model, title) => {
      props.navigation.navigate('ListScreen', { model, title });
    }

    return (
      <TouchableOpacity style={styles.itemContainer} onPress={() => openListingPage(item.name, item.pluralName)}>
        <HeadingWrapper>
          <MediumIcon name={item.icon} />
          <Text style={styles.item}>{item.name}</Text>
        </HeadingWrapper>
        <Chevron />
      </TouchableOpacity>
    )
  }

  return (
    <SafeAreaView style={styles.container}>
      <FlatList
        data={Models}
        renderItem={renderItem}
        keyExtractor={item => item.name}
      />
    </SafeAreaView>
  )
}

const styles = StyleSheet.create({
  container: { margin: 5, flex: 1, padding: 16, paddingTop: 30 },

  itemContainer: {
    flex: 1,
    flexDirection: "row",
    alignItems: 'center',
    backgroundColor: '#fff',
    marginBottom: 5,
  },

  content: {
    flex: 1,
    flexDirection: 'row',
  },

  chevron: {
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 5,
  },

  item: {
    padding: 10,
    marginVertical: 8,
    fontSize: 18,
    alignSelf: 'stretch',
  },
});

export default SchemaScreen;
